import CryptoJS from "crypto-js";

// const url = "http://localhost:8080";
// const url = "http://192.168.13.105:8080";
const url = "https://node.staystation.encrobytes.com";

const userAuthorization = "898sf&lg7";
const localStorageEncryptionKey = "EncryptionKeyThisIsAStrongEncryptionKey123!";

const endpoints = {
  contact_us: "/contact_us",
  call_back: "/call_back",
  login: "/client/login",
  otp: "/client/otp",
};

const adminHeader = {
  "Content-Type": "application/json",
  type: "admin",
  authorization: "bdjsbdsjs7bHR@4567",
  key: "jsjdj744%5GSHr45",
};

const adminpoints = {
  login: "/admin/login",
  logout: "/admin/logout",
  dashboard: "/dashboard/view",
  auth: "/admin/auth",

  client_view: "/client/view",
  client_add: "/client/add",
  client_edit: "/client/edit",
  client_delete: "/client/delete",
  client_count: "/client/count",

  hotel_view: "/hotel/view",
  hotel_add: "/hotel/add",
  hotel_edit: "/hotel/edit",
  hotel_delete: "/hotel/delete",
  hotel_count: "/hotel/count",

  client_ticket_view: "/client_ticket/view",
  client_ticket_add: "/client_ticket/add",
  client_ticket_edit: "/client_ticket/edit",
  client_ticket_delete: "/client_ticket/delete",
  client_ticket_status: "/client_ticket/status",

  client_ticket_history_view: "/client_ticket_history/view",
  client_ticket_history_add: "/client_ticket_history/add",
  client_ticket_history_edit: "/client_ticket_history/edit",
  client_ticket_history_delete: "/client_ticket_history/delete",

  subscription_view: "/subscription/view",
  subscription_add: "/subscription/add",
  subscription_edit: "/subscription/edit",
  subscription_delete: "/subscription/delete",

  otp: "/admin/otp",
};

const clientHeader = {
  "Content-Type": "application/json",
  type: "client",
  authorization: "$^CLiENt$%^&I&^@4567",
  key: "jsjdj744%5GSHr45",
};

const clientpoints = {
  dashboard: "/hotel_dashboard/view",
  logout: "/client/logout",

  client_view: "/client/view",
  client_add: "/client/add",
  client_edit: "/client/edit",
  client_delete: "/client/delete",
  client_count: "/client/count",

  hotel_count: "/hotel/count",
  hotel_view: "/hotel/view",
  hotel_add: "/hotel/add",
  hotel_edit: "/hotel/edit",
  hotel_delete: "/hotel/delete",

  role_add: "/role/add",
  role_count: "/role/count",
  role_view: "/role/view",
  role_edit: "/role/edit",
  role_delete: "/role/delete",

  team_add: "/team/add",
  team_count: "/team/count",
  team_view: "/team/view",
  team_edit: "/team/edit",
  team_delete: "/team/delete",
  team_logout: "/team/logout",

  user_count: "/user/count",
  user_view: "/user/view",
  user_add: "/user/add",
  user_edit: "/user/edit",
  user_delete: "/user/delete",

  booking_count: "/booking/count",
  booking_view: "/booking/view",
  booking_add: "/booking/add",
  booking_edit: "/booking/edit",
  booking_delete: "/booking/delete",

  coupon_count: "/coupon/count",
  coupon_view: "/coupon/view",
  coupon_add: "/coupon/add",
  coupon_edit: "/coupon/edit",
  coupon_delete: "/coupon/delete",

  faq_add: "/faq/add",
  faq_count: "/faq/count",
  faq_view: "/faq/view",
  faq_edit: "/faq/edit",
  faq_delete: "/faq/delete",

  pricing_type_add: "/pricing_type/add",
  pricing_type_count: "/pricing_type/count",
  pricing_type_view: "/pricing_type/view",
  pricing_type_edit: "/pricing_type/edit",
  pricing_type_delete: "/pricing_type/delete",

  pricing_type_variant_add: "/pricing_type_variant/add",
  pricing_type_variant_count: "/pricing_type_variant/count",
  pricing_type_variant_view: "/pricing_type_variant/view",
  pricing_type_variant_edit: "/pricing_type_variant/edit",
  pricing_type_variant_delete: "/pricing_type_variant/delete",

  room_type_add: "/room_type/add",
  room_type_count: "/room_type/count",
  room_type_view: "/room_type/view",
  room_type_edit: "/room_type/edit",
  room_type_delete: "/room_type/delete",

  property_type_add: "/property_type/add",
  property_type_count: "/property_type/count",
  property_type_view: "/property_type/view",
  property_type_edit: "/property_type/edit",
  property_type_delete: "/property_type/delete",

  property_amenity_add: "/property_amenity/add",
  property_amenity_count: "/property_amenity/count",
  property_amenity_view: "/property_amenity/view",
  property_amenity_edit: "/property_amenity/edit",
  property_amenity_delete: "/property_amenity/delete",

  apartment_amenity_add: "/apartment_amenity/add",
  apartment_amenity_count: "/apartment_amenity/count",
  apartment_amenity_view: "/apartment_amenity/view",
  apartment_amenity_edit: "/apartment_amenity/edit",
  apartment_amenity_delete: "/apartment_amenity/delete",

  area_add: "/area/add",
  area_count: "/area/count",
  area_view: "/area/view",
  area_edit: "/area/edit",
  area_delete: "/area/delete",

  property_add: "/property/add",
  property_count: "/property/count",
  property_view: "/property/view",
  property_edit: "/property/edit",
  property_delete: "/property/delete",

  room_add: "/room/add",
  room_count: "/room/count",
  room_view: "/room/view",
  room_edit: "/room/edit",
  room_delete: "/room/delete",

  room_price_formula_add: "/room_price_formula/add",
  room_price_formula_count: "/room_price_formula/count",
  room_price_formula_view: "/room_price_formula/view",
  room_price_formula_edit: "/room_price_formula/edit",
  room_price_formula_delete: "/room_price_formula/delete",

  room_inventory_price_add: "/room_inventory_price/add",
  room_inventory_price_count: "/room_inventory_price/count",
  room_inventory_price_view: "/room_inventory_price/view",
  room_inventory_price_view_by_formula: "/room_inventory_price/view-by-formula",
  room_inventory_price_edit: "/room_inventory_price/edit",
  room_inventory_price_delete: "/room_inventory_price/delete",

  review_add: "/review/add",
  review_count: "/review/count",
  review_view: "/review/view",
  review_edit: "/review/edit",
  review_delete: "/review/delete",

  feedback_add: "/feedback/add",
  feedback_count: "/feedback/count",
  feedback_view: "/feedback/view",
  feedback_edit: "/feedback/edit",
  feedback_delete: "/feedback/delete",

  user_add: "/user/add",
  user_count: "/user/count",
  user_view: "/user/view",
  user_edit: "/user/edit",
  user_delete: "/user/delete",

  company_add: "/company/add",
  company_count: "/company/count",
  company_view: "/company/view",
  company_edit: "/company/edit",
  company_delete: "/company/delete",

  member_add: "/member/add",
  member_count: "/member/count",
  member_view: "/member/view",
  member_edit: "/member/edit",
  member_delete: "/member/delete",

  subscription_view: "/subscription/view",
  subscription_add: "/subscription/add",
  subscription_edit: "/subscription/edit",
  subscription_delete: "/subscription/delete",

  client_ticket_view: "/client_ticket/view",
  client_ticket_add: "/client_ticket/add",
  client_ticket_edit: "/client_ticket/edit",
  client_ticket_delete: "/client_ticket/delete",
  client_ticket_status: "/client_ticket/status",

  client_ticket_history_view: "/client_ticket_history/view",
  client_ticket_history_add: "/client_ticket_history/add",
  client_ticket_history_edit: "/client_ticket_history/edit",
  client_ticket_history_delete: "/client_ticket_history/delete",

  ticket_add: "/ticket/add",
  ticket_count: "/ticket/count",
  ticket_view: "/ticket/view",
  ticket_edit: "/ticket/edit",
  ticket_delete: "/ticket/delete",

  ticket_history_view: "/ticket_history/view",
  ticket_history_add: "/ticket_history/add",
  ticket_history_edit: "/ticket_history/edit",
  ticket_history_delete: "/ticket_history/delete",

  visitor_view: "/visitor/view",
  visitor_add: "/visitor/add",
  visitor_edit: "/visitor/edit",
  visitor_delete: "/visitor/delete",

  enquiry_view: "/enquiry/view",
  enquiry_add: "/enquiry/add",
  enquiry_edit: "/enquiry/edit",
  enquiry_delete: "/enquiry/delete",

  expense_view: "/expense/view",
  expense_add: "/expense/add",
  expense_edit: "/expense/edit",
  expense_delete: "/expense/delete",

  post_view: "/post/view",
  post_add: "/post/add",
  post_edit: "/post/edit",
  post_delete: "/post/delete",

  post_comment_view: "/post_comment/view",
  post_comment_add: "/post_comment/add",
  post_comment_edit: "/post_comment/edit",
  post_comment_delete: "/post_comment/delete",
  post_comment_count: "/post_comment/count",

  post_like_view: "/post_like/view",
  post_like_add: "/post_like/add",
  post_like_edit: "/post_like/edit",
  post_like_count: "/post_like/count",

  event_view: "/event/view",
  event_add: "/event/add",
  event_edit: "/event/edit",
  event_delete: "/event/delete",

  offer_view: "/offer/view",
  offer_add: "/offer/add",
  offer_edit: "/offer/edit",
  offer_delete: "/offer/delete",

  event_apply_view: "/event_apply/view",
  event_apply_add: "/event_apply/add",
  event_apply_edit: "/event_apply/edit",
  event_apply_delete: "/event_apply/delete",

  billing_view: "/billing/view",
  billing_add: "/billing/add",
  billing_edit: "/billing/edit",
  billing_delete: "/billing/delete",
};

const teamHeader = {
  "Content-Type": "application/json",
  type: "team",
  authorization: "$^tEAm$%^&)%T$%^6^",
  key: "J7TeA^&*mT%j*&^&(E",
};

const teampoints = {
  login: "/team/login",
  logout: "/team/logout",
};

const admin_prefix = "/Br!Y7o&p45fM/portal/";
const client_prefix = "/login";

function whoAmIHeaders(params) {
  if (params === "client") {
    let obj = {
      "Content-Type": "application/json",
      type: "client",
      authorization: "$^CLiENt$%^&I&^@4567",
      key: "jsjdj744%5GSHr45",
    };

    return obj;
  } else if (params === "team") {
    let obj = {
      "Content-Type": "application/json",
      type: "team",
      authorization: "$^tEAm$%^&)%T$%^6^",
      key: "jsjdj744%5GSHr45",
    };

    return obj;
  }

  // else if (params.type === 'member') {}
}

// Encryption function
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), localStorageEncryptionKey).toString();
};

// Decryption function
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, localStorageEncryptionKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
};

// Set data to local storage after encrypting
const setEncryptedDataToLocalStorage = (key, data) => {
  const encryptedData = encryptData(data);
  localStorage.setItem(key, encryptedData);
};

// Get data from local storage and decrypt
const getDecryptedDataFromLocalStorage = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    return decryptData(encryptedData);
  }
  return null;
};

export {
  url,
  userAuthorization,
  endpoints,
  adminpoints,
  admin_prefix,
  adminHeader,
  clientpoints,
  teampoints,
  clientHeader,
  teamHeader,
  client_prefix,
  whoAmIHeaders,
  setEncryptedDataToLocalStorage,
  getDecryptedDataFromLocalStorage,
};
