import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { format } from "date-fns";

import "./component.css";

function Component({ view = "view", isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  const [mode, setMode] = useState(view);

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [formData, setFormData] = useState(initialFormData);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    user: "",
    area: "",
    property: "",
    room: "",
    pricingType: "",
    showRooms: [],
  });

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let bookingId = data;

  useEffect(() => {
    fetchAreas();
    fetchUsers();
    fetchPricingTypes();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ filters: {} }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setUsers(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await fetch(url + clientpoints.area_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: {} }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setAreas(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchPricingTypes = async () => {
    try {
      const response = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: {} }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setPricingTypes(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const area = areas.find((item) => item._id === formData.area) || "";
    const fetchProperties = async () => {
      try {
        const response = await fetch(url + clientpoints.property_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { area: area._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setProperties(data.Data);
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.area) {
      fetchProperties();
    }
  }, [formData.area, areas]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id) || "";

    const fetchRooms = async () => {
      try {
        const response = await fetch(url + clientpoints.room_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setRooms(data.Data);
        setSelectedValues((prev) => ({
          ...prev,
          property: property ? { label: property.title, value: property._id } : "",
          showRooms: data.Data,
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id) {
      fetchRooms();
    }
  }, [formData.property_id, properties]);

  useEffect(() => {
    const area = areas.find((item) => item._id === formData.area);
    setSelectedValues((prev) => ({
      ...prev,
      area: area ? { label: area.area_name, value: area._id } : "",
    }));
  }, [formData.area, areas]);

  useEffect(() => {
    const user = users.find((item) => item._id === formData.user_id);

    setSelectedUser(user);

    setSelectedValues((prev) => ({
      ...prev,
      user: user ? { label: user.email, value: user._id } : "",
    }));
  }, [formData.user_id, users]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id);
    setSelectedValues((prev) => ({
      ...prev,
      property: property ? { label: property.title, value: property._id } : "",
      // showApartments: property ? apartments.filter((item) => item.propertyId == property._id) : [],
    }));
    // setFormData()
  }, [formData.property_id, properties]);

  useEffect(() => {
    const room = rooms.find((item) => item._id === formData.room_id);

    setSelectedRoom(room);

    setSelectedValues((prev) => ({
      ...prev,
      room: room ? { label: room.name, value: room._id } : "",
    }));
  }, [formData.room_id, rooms]);

  useEffect(() => {
    const pricingType = pricingTypes.find((item) => item._id === formData.pricing_type_id);
    setSelectedValues((prev) => ({
      ...prev,
      pricingType: pricingType ? { label: pricingType.name, value: pricingType._id } : "",
    }));
  }, [formData.pricing_type_id, pricingTypes]);

  useEffect(() => {
    let cancellationTypeValue;

    if (formData.deal_type === rateCategories[0]) {
      cancellationTypeValue = cancellationTypes[0];
    } else {
      cancellationTypeValue = cancellationTypes[1];
    }

    setFormData((prev) => ({
      ...prev,
      cancellation_type: cancellationTypeValue,
    }));
  }, [formData.deal_type]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      total_nights: formData.check_in && formData.check_out ? Math.ceil((new Date(formData.check_out) - new Date(formData.check_in)) / (1000 * 60 * 60 * 24)) : "",
    }));
  }, [formData.check_in, formData.check_out]);

  useEffect(() => {
    if (selectedUser) {
      setFormData((prev) => ({
        ...prev,
        billing_detail: {
          billing_name: selectedUser.billing_name,
          billing_address: selectedUser.billing_address,
          gstin: selectedUser.gstin,
          tax_type: "",
          base_amount: formData.total_base_price,
          tax_amount: formData.tax_amount,
          total_amount: formData.total_cost,
        },
      }));
    }
  }, [selectedUser, formData.total_base_price, formData.tax_amount, formData.total_cost]);

  useEffect(() => {
    const baseRatePerNight = parseInt(selectedRoom?.base_price);
    const totalBasePrice = baseRatePerNight * parseInt(formData.total_nights);

    if (formData.total_nights && !formData.total_base_price) {
      setFormData((prev) => ({
        ...prev,
        total_base_price: totalBasePrice,
      }));
    }
  }, [formData.total_nights, selectedRoom]);

  useEffect(() => {
    const tax = parseInt(formData.tax_amount) || 0;
    const discount = parseInt(formData.discount_value) || 0;

    if (formData.total_base_price) {
      const discountedPrice = formData.total_base_price - discount;

      setFormData((prev) => ({
        ...prev,
        total_cost: discountedPrice + tax,
      }));
    }
  }, [formData.total_base_price, formData.tax_amount, formData.discount_value]);

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    if (!formData.user_id.trim()) {
      errors.user_id = "User is required";
      isValid = false;
    }
    if (!formData.property_id.trim()) {
      errors.property_id = "Property is required";
      isValid = false;
    }
    if (!formData.room_id.trim()) {
      errors.room_id = "Room is required";
      isValid = false;
    }
    if (!formData.pricing_type_id.trim()) {
      errors.pricing_type_id = "Pricing Type is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check-in date is required";
      isValid = false;
    }
    if (!formData.check_out.trim()) {
      errors.check_out = "Check-out date is required";
      isValid = false;
    }
    if (formData.guests <= 0) {
      errors.guests = "Guests cannot be zero";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen && bookingId) {
      setLoading(true);
      fetchData(bookingId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, bookingId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.booking_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch booking data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);

        setFormData({
          // ...formData,
          ...response_data,
          user_id: response_data.user_id._id,
          area: response_data.area._id,
          property_id: response_data.property_id._id,
          room_id: response_data.room_id._id,
          pricing_type_id: response_data.pricing_type_id._id,
          check_in: format(response_data.check_in, "yyyy-MM-dd"),
          check_out: format(response_data.check_out, "yyyy-MM-dd"),
        });
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.booking_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className='modal-body'
                    style={{
                      maxHeight: "70vh",
                      overflowY: "scroll",
                      padding: "0 15px",
                    }}
                  >
                    <div className=''>
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <label htmlFor='user_id' className='col-form-label'>
                            User
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={users?.map((user) => ({
                              label: `${user.name} (${user.email})`,
                              value: user._id,
                            }))}
                            value={selectedValues.user}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                user_id: val.value,
                              }))
                            }
                            placeholder='Select User...'
                          />
                          {errors.user_id && <div className='invalid-feedback'>{errors.user_id}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='area' className='col-form-label'>
                            Area
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={areas?.map((area) => ({
                              label: `${area.area_name}`,
                              value: area._id,
                            }))}
                            value={selectedValues.area}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                area: val.value,
                              }))
                            }
                            placeholder='Select Area...'
                          />
                          {errors.area && <div className='invalid-feedback'>{errors.area}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='property_id' className='col-form-label'>
                            Property
                          </label>
                          <Select
                            isDisabled={!formData.area || mode === "view"}
                            required
                            options={properties?.map((property) => ({
                              label: `${property.title}`,
                              value: property._id,
                            }))}
                            value={selectedValues.property}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }))
                            }
                            placeholder='Select Property...'
                          />
                          {errors.property_id && <div className='invalid-feedback'>{errors.property_id}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='room_id' className='col-form-label'>
                            Room
                          </label>
                          <Select
                            isDisabled={!formData.property_id || mode === "view"}
                            required
                            options={rooms?.map((room) => ({
                              label: room.name,
                              value: room._id,
                            }))}
                            value={selectedValues.room}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                room_id: val.value,
                              }))
                            }
                            placeholder='Select Room...'
                          />
                          {errors.room_id && <div className='invalid-feedback'>{errors.room_id}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='pricing_type' className='col-form-label'>
                            Pricing Type
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={pricingTypes?.map((type) => ({
                              label: type.name,
                              value: type._id,
                            }))}
                            value={selectedValues.pricingType}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                pricing_type_id: val.value,
                              }))
                            }
                            placeholder='Select Pricing Type...'
                          />
                          {errors.pricing_type_id && <div className='invalid-feedback'>{errors.pricing_type_id}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='check_in' className='col-form-label'>
                            Check-in Date
                          </label>
                          <input disabled={mode === "view"} type='date' className={`form-control ${errors.check_in ? "is-invalid" : ""}`} id='check_in' name='check_in' value={formData.check_in} onChange={handleChange} />
                          {errors.check_in && <div className='invalid-feedback'>{errors.check_in}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='check_out' className='col-form-label'>
                            Check-out Date
                          </label>
                          <input disabled={mode === "view"} type='date' className={`form-control ${errors.check_out ? "is-invalid" : ""}`} id='check_out' name='check_out' value={formData.check_out} onChange={handleChange} />
                          {errors.check_out && <div className='invalid-feedback'>{errors.check_out}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='total_nights' className='col-form-label'>
                            Total Nights
                          </label>
                          <input disabled type='number' className={`form-control ${errors.total_nights ? "is-invalid" : ""}`} id='total_nights' name='total_nights' value={formData.total_nights} onChange={handleChange} />
                          {errors.total_nights && <div className='invalid-feedback'>{errors.total_nights}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='guests' className='col-form-label'>
                            Number of Guests
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control ${errors.guests ? "is-invalid" : ""}`} id='guests' name='guests' value={formData.guests} onChange={handleChange} />
                          {errors.guests && <div className='invalid-feedback'>{errors.guests}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='children' className='col-form-label'>
                            Number of Children
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control ${errors.children ? "is-invalid" : ""}`} id='children' name='children' value={formData.children} onChange={handleChange} />
                          {errors.children && <div className='invalid-feedback'>{errors.children}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='guest_name' className='col-form-label'>
                            Guest Name
                          </label>
                          <input disabled={mode === "view"} type='text' className={`form-control ${errors.guest_name ? "is-invalid" : ""}`} name='guest_name' id='guest_name' value={formData.guest_name} onChange={handleChange} />
                          {errors.guest_name && <div className='invalid-feedback'>{errors.guest_name}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='phone' className='col-form-label'>
                            Phone
                          </label>
                          <input disabled={mode === "view"} type='text' className={`form-control ${errors.phone ? "is-invalid" : ""}`} name='phone' id='phone' value={formData.phone} onChange={handleChange} />
                          {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='email' className='col-form-label'>
                            Email
                          </label>
                          <input disabled={mode === "view"} type='email' className={`form-control ${errors.email ? "is-invalid" : ""}`} name='email' id='email' value={formData.email} onChange={handleChange} />
                          {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='special_request' className='col-form-label'>
                            Special Request
                          </label>
                          <textarea disabled={mode === "view"} rows={4} type='text' className={`form-control ${errors.special_request ? "is-invalid" : ""}`} name='special_request' id='special_request' value={formData.special_request} onChange={handleChange} />
                          {errors.special_request && <div className='invalid-feedback'>{errors.special_request}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='deal_type' className='col-form-label'>
                            Deal Type
                          </label>
                          <select
                            disabled={mode === "view"}
                            id='deal_type'
                            className={`form-select`}
                            value={formData.deal_type}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                deal_type: e.target.value,
                              }))
                            }
                          >
                            {rateCategories.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='cancellation_type' className='col-form-label'>
                            Cancellation Type
                          </label>
                          <select
                            disabled
                            id='cancellation_type'
                            className={`form-select`}
                            value={formData.cancellation_type}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                cancellation_type: e.target.value,
                              }))
                            }
                          >
                            {cancellationTypes.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='payment_mode' className='col-form-label'>
                            Payment Mode
                          </label>
                          <select
                            disabled={mode === "view"}
                            id='payment_mode'
                            className={`form-select`}
                            value={formData.payment_mode}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                payment_mode: e.target.value,
                              }))
                            }
                          >
                            {paymentModes.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='total_base_price' className='col-form-label'>
                            Total Base Price
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control ${errors.total_base_price ? "is-invalid" : ""}`} id='total_base_price' name='total_base_price' value={formData.total_base_price} onChange={handleChange} />
                          {errors.total_base_price && <div className='invalid-feedback'>{errors.total_base_price}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='discount_value' className='col-form-label'>
                            Discount Value
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='discount_value' name='discount_value' value={formData.discount_value} onChange={handleChange} />
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='tax_amount' className='col-form-label'>
                            Tax Amount
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='tax_amount' name='tax_amount' value={formData.tax_amount} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='total_cost' className='col-form-label'>
                            Grand Total
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='total_cost' name='total_cost' value={formData.total_cost} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-12'>
                          <label htmlFor='status' className='col-form-label'>
                            Booking Status
                          </label>
                          <select
                            disabled={mode === "view"}
                            id='status'
                            className={`form-select`}
                            value={formData.status}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                status: e.target.value,
                              }))
                            }
                          >
                            {bookingStatus.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button>
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

export const bookingStatus = ["pending", "completed", "cancelled"];
export const rateCategories = ["Hot Deal", "Best Available Rate"];
export const paymentModes = ["UPI", "Card", "Net Banking"];
export const cancellationTypes = ["nonRefundable", "refundable"];

const initialFormData = {
  user_id: "",
  area: "",
  property_id: "",
  room_id: "",
  pricing_type_id: "",
  check_in: "",
  check_out: "",
  total_nights: 0,
  guests: 1,
  children: 0,
  guest_name: "",
  phone: "",
  email: "",
  special_request: "",
  payment_mode: paymentModes[0],
  cancellation_type: cancellationTypes[0],
  deal_type: rateCategories[0],
  total_base_price: 0,
  discount_value: 0,
  discount_type: "",
  promo_discount_value: "",
  promo_code: "",
  tax_amount: 0,
  total_cost: 0,
  status: bookingStatus[0],
  billing_detail: {
    billing_name: "",
    billing_address: "",
    gstin: "",
    tax_type: "",
    base_amount: 0,
    tax_amount: 0,
    total_amount: 0,
  },
};
