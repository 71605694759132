import React, { useState, useEffect, useRef } from "react";
import { url, clientpoints, clientHeader } from "../../../../../../lib/lib.js";
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ setHotelName, searchValueProps, suggestionsProps, showDropdownProps, form = true }) => {
  const { hotelId } = useParams();

  const { searchValue, setSearchValue } = searchValueProps;
  const { suggestions, setSuggestions } = suggestionsProps;
  const { showDropdown, setShowDropdown } = showDropdownProps;
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  let [countIndex, setCountIndex] = useState(0);
  const loadOptions = async () => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: searchValue }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: "", page: page, size: perPage, sorting: { createdAt: "1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestions((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: clientHeader,
        // body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
      setTotalPages(responseData.totalCount);
      setSearchValue("");
    } catch (error) {
      console.error("Error fetching options:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      const { name, _id } = selectedOption;
      setSearchValue(name);
      setHotelName(_id);
      setShowDropdown(name);
      setSuggestions([]);
    } else if (selectedOption == "") {
      setSearchValue("All Users");
      setShowDropdown("All Users");
      setHotelName("");
      setSuggestions([]);
    } else {
      setSearchValue("");
      setShowDropdown("");
      setHotelName("");
      setSuggestions([]);
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValue(showDropdown);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  return (
    <div className='dropdown' ref={dropdownRef}>
      <i className='ri-arrow-down-s-line ab1' onClick={loadOptionsOnClick}></i>
      <input
        autoComplete='off'
        ref={inputRef}
        placeholder={form ? "Search for Users..." : "Select Users"}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        onKeyUp={() => loadOptions(searchValue)}
        onClick={loadOptionsOnClick}
        id='hotelName'
        className='search-input form-control customSelect w-100'
      />
      {isLoading ? (
        <div className='spinner-border text-primary my_spin' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        suggestions.length > 0 && (
          <ul id='dropdownMenu' onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className='dropdown-menu mydrop_hgt'>
            {form ? (
              <li onClick={() => handleOptionClick("")}>
                All Users<span className='hidden' data-client-id={""}></span>
              </li>
            ) : (
              ""
            )}
            {suggestions.length > 0 ? (
              suggestions.map((user) => (
                <li key={user._id} onClick={() => handleOptionClick(user)}>
                  {user.name} {user.email && user.email ? `( ${user.email} ) ` : ""}
                  <span className='hidden' data-user-id={user._id} data-client-id={user.client_id ? user.client_id._id : ""}></span>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        )
      )}
    </div>
  );
};

export default Component;
