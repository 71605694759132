import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./component.css";
import { endpoints, url, clientpoints, setEncryptedDataToLocalStorage, getDecryptedDataFromLocalStorage } from "../../../lib/lib.js";
import { AuthUserContext } from "../../../lib/AuthUserContext.js";

const Component = () => {
  const { setWhoAmI } = useContext(AuthUserContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  let loginHeaderForClient = {
    "Content-Type": "application/json",
    type: "client",
    authorization: "$^CLiENt$%^&I&^@4567",
    key: "j$%9&^nh@5CLIentr45",
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!email.trim()) {
      setError("Email is required");
      return;
    }
    const ipAddress = await fetch("https://api64.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip);
    try {
      const response = await fetch(url + endpoints.login, {
        method: "POST",
        headers: loginHeaderForClient,
        credentials: "include",
        body: JSON.stringify({ email, ipAddress }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.Status) {
        setShowOtpInput(true);
      } else {
        setError(data.Message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred while logging in. Please try again.");
      setLoading(false);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/\D/g, "");
    if (formattedValue.length > 6) {
      formattedValue = formattedValue.slice(0, 6);
    }
    // formattedValue = formattedValue.padEnd(6, '');
    // setOtp(formattedValue);
    // setOtp(value);
    setOtp(formattedValue);
  };

  const otpHandler = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!otp.trim()) {
      setError("O.T.P is required");
      setLoading(false);
      return;
    } else if (!/^\d{6}$/.test(otp.trim())) {
      setError("O.T.P must be a 6-digit number");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(url + endpoints.otp, {
        method: "POST",
        headers: loginHeaderForClient,
        credentials: "include",
        body: JSON.stringify({ otp }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.Status) {
        setShowOtpInput(true);

        const getLocalStorageData = getDecryptedDataFromLocalStorage("type");

        if (getLocalStorageData != null) {
          if (getLocalStorageData) {
            localStorage.clear();
            logoutUser();
          }
        }
        setWhoAmI("client");
        setEncryptedDataToLocalStorage("type", "client");
        navigate("/hotels");
      } else {
        setError(data.Message);
      }
      setLoading(false);
    } catch (error) {
      // console.error('Error:', error.message);
      setError("An error occurred while logging in. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  async function logoutUser() {
    try {
      let response = await fetch(url + clientpoints.team_logout, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  }

  return (
    <div className='Inclusive-login-page'>
      <div className={`login-big-wrapper ${showOtpInput ? "show-otp" : ""}`}>
        <div className='section-wrapper'>
          <Link to='/'>
            <img className='log-inlogo' src='/img/staystation.png' alt='Logo' />{" "}
          </Link>

          {!showOtpInput ? (
            <div className='top-login-explain'>
              <h2 className='login-txt'>Login To Your Account</h2>
              {/* <p>Please Login Your Account, Thank You!</p> */}
            </div>
          ) : (
            <div className='top-login-explain'>
              <h2 className='otprnter-txt'>Enter OTP</h2>
              {/* <h6 className='otpsent'>The OTP has been successfully sent to <b>Sagarsharma3013@gmail.com{email}</b></h6> */}
            </div>
          )}

          <form onSubmit={loginHandler}>
            {error && <div className='error_message'>{error}</div>}
            <div className='input-wrapper'>
              <input type='email' required id='email' placeholder='example@gmail.com' onChange={(e) => setEmail(e.target.value)} value={email} tabIndex={1} disabled={showOtpInput} />
              <label htmlFor='email'>Email</label>
            </div>
            {showOtpInput ? (
              ""
            ) : (
              <div>
                <p className='try_again_as_privacy_and_policy_and_terms'>
                  By logging in, you accept our{" "}
                  <span
                    className='hover_class_change privacy_and_policy_and_terms'
                    onClick={() => {
                      navigate("/privacy");
                      setError("");
                    }}
                  >
                    privacy policy
                  </span>{" "}
                  and{" "}
                  <span
                    className='hover_class_change privacy_and_policy_and_terms'
                    onClick={() => {
                      navigate("/terms");
                      setError("");
                    }}
                  >
                    terms & conditions.
                  </span>
                </p>
                <button type='submit' className='subbmitbtn'>
                  {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : "Send OTP"}
                </button>
              </div>
            )}
          </form>
          {showOtpInput ? (
            <form onSubmit={otpHandler}>
              {/* {error && <div className="error_message">{error}</div>} */}
              <div className='input-wrapper'>
                <input
                  type='text' // Change input type to text
                  required
                  id='otp'
                  autoComplete='one-time-code' // Use 'one-time-code' for better browser support
                  className='loginPass' // You can style this as needed
                  placeholder='XXXXXX'
                  onChange={handleOtpChange}
                  // onKeyDown={handleKeyDown}
                  value={otp}
                  tabIndex={2}
                />
                <label htmlFor='otp'>One Time Password (OTP)</label>
              </div>
              <p className='try_again_as_privacy_and_policy_and_terms'>
                By logging in, you accept our{" "}
                <span
                  className='hover_class_change privacy_and_policy_and_terms'
                  onClick={() => {
                    navigate("/privacy");
                    setError("");
                  }}
                >
                  privacy policy
                </span>{" "}
                and{" "}
                <span
                  className='hover_class_change privacy_and_policy_and_terms'
                  onClick={() => {
                    navigate("/terms");
                    setError("");
                  }}
                >
                  terms & conditions.
                </span>
              </p>
              <button type='submit' className='subbmitbtn'>
                {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : "Submit"}
              </button>
              <p
                className='try_again'
                onClick={() => {
                  setShowOtpInput(false);
                  setOtp("");
                  setError("");
                }}
              >
                Facing any issues? <span className='hover_class_change'>Please try again.</span>
              </p>
            </form>
          ) : (
            ""
          )}
        </div>
        <div className='login-banner-section '>
          <img src='/img/login2.png' alt='banner' height='100%' className='img-fluid' />
          {/* <img src="https://images.unsplash.com/photo-1556742044-3c52d6e88c62?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="banner" width='100%' height='100%' /> */}
        </div>
      </div>
    </div>
  );
};

export default Component;
