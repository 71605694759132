import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getDecryptedDataFromLocalStorage } from "./lib/lib.js";
import { AuthUserContext } from "./lib/AuthUserContext.js";

// Start Website Route
import Home from "./website/screens/Home.js";
import Privacy from "./website/screens/Privacy.js";
import Terms from "./website/screens/Terms.js";
// import NotFound from "./website/screens/NotFound.js";
import HotelsRoutes from "./panels/hotels/hotels.js";

import AdminRoute from "./panels/admin/admin.js";

// import DashboardTemp from "./panels/nav/screens/Dashboard.js";
// End Website Route
// import HotelsHome from "./panels/hotels/screens/Home.js";
import ClientRoutes from "./panels/client/client.js";
import TeamRoutes from "./panels/team/team.js";
// import PrivateRoutes from "./utils/PrivateRoute.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [whoAmI, setWhoAmI] = useState(""); // client, team, member as a type
  const [typeTeamRoleObject, setTypeTeamRoleObject] = useState({}); //  Team Role Object

  useEffect(() => {
    const getLocalStorageData = getDecryptedDataFromLocalStorage("type");
    if (whoAmI === null && !getLocalStorageData) {
      window.location.reload(); // Refresh the page if both whoAmI and local storage data are null
    } else if (getLocalStorageData) {
      setWhoAmI(getLocalStorageData);
    }
  }, []);

  return (
    <>
      <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject }}>
        <Router>
          <Routes>
            <Route path='Br!Y7o&p45fM/portal/*' element={<AdminRoute />} />
            <Route path='/*' element={<ClientRoutes />} />
            <Route path='/:hotelId/team/*' element={<TeamRoutes />} /> {/* Team Route */}
            <Route path='/hotel/:hotelId/backend/*' element={<HotelsRoutes />} />
            <Route exact path='/' element={<Home />} />
            <Route exact path='/privacy' element={<Privacy />} />
            <Route exact path='/terms' element={<Terms />} />
            {/* <Route exact path='/login' element={<Login />} /> */}
            {/* <Route exact path='/login' element={<Login />} /> */}
            {/* Private routes hotel panel */}
            {/* <Route path='/hotel' element={<PrivateRoutes />}> */}
            {/* </Route> */}
          </Routes>
        </Router>
      </AuthUserContext.Provider>
    </>
  );
}

root.render(<App />);
