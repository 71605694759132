import React, { useState, useEffect, useRef } from "react";
import { url, adminpoints, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { useNavigate } from "react-router-dom";

const Component = ({ setHotelName, clientName, searchValueProps, suggestionsProps, showDropdownProps, setPagination }) => {
  const navigate = useNavigate();
  const { searchValue, setSearchValue } = searchValueProps;
  const { suggestions, setSuggestions } = suggestionsProps;
  const { showDropdown, setShowDropdown } = showDropdownProps;
  const client_id = clientName;
  const [isLoading, setIsLoading] = useState(false);
  const filters = client_id ? { client_id } : {};

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  let [countIndex, setCountIndex] = useState(0);
  const loadOptions = async () => {
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ filters, search: searchValue }),
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ filters, search: "", page: page, size: perPage, sorting: { createdAt: "-1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestions((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ filters, search: "", page: countIndex + 1, size: 10, sorting: { createdAt: "-1" } }),
        credentials: "include",
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
      setTotalPages(responseData.totalCount);
      setSearchValue("");
    } catch (error) {
      setIsLoading(false);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({ ...prevPagination, pageIndex: currentPageIndex }));
    const urlParams = new URLSearchParams(window.location.search);
    const idGet = urlParams.get("id");
    if (selectedOption) {
      const { hotel_name, _id } = selectedOption;
      setSearchValue(hotel_name);
      setHotelName(_id);
      setShowDropdown(hotel_name);
      setSuggestions([]);
    } else if (selectedOption == "") {
      setSearchValue("All Hotels");
      setShowDropdown("All Hotels");
      setHotelName("");
      setSuggestions([]);
    } else {
      setSearchValue("");
      setShowDropdown("");
      setHotelName("");
      setSuggestions([]);
    }

    if (idGet != null && idGet.trim() != "") {
      const idFromUrl = urlParams.delete("id");
      navigate(admin_prefix + "subscription");
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValue(showDropdown);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  return (
    <div className='sd2drop'>
      <div className='dropdown' ref={dropdownRef}>
        <i className='fa fa-angle-down ab1' onClick={loadOptionsOnClick}></i>
        <input
          autoComplete='off'
          ref={inputRef}
          placeholder='Search Hotels...'
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onKeyUp={() => loadOptions(searchValue)}
          onClick={loadOptionsOnClick}
          id='hotelName'
          className='search-input form-control customSelect'
        />
        {isLoading ? (
          <div className='spinner-border text-primary my_spin' role='status'>
            {/* <span className='sr-only'>Loading...</span> */}
          </div>
        ) : (
          suggestions.length > 0 && (
            <ul id='dropdownMenu sd2drop' onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className='dropdown-menu mydrop_hgt'>
              <li onClick={() => handleOptionClick("")}>
                All Hotels<span className='hidden' data-client-id={""}></span>
              </li>
              {suggestions.length > 0 ? (
                suggestions.map((hotel) => (
                  <li key={hotel._id} onClick={() => handleOptionClick(hotel)}>
                    {hotel.hotel_name} {hotel.client_id && hotel.client_id.name ? `( ${hotel.client_id.name} ) ` : ""}
                    <span className='hidden' data-hotel-id={hotel._id} data-client-id={hotel.client_id ? hotel.client_id._id : ""}></span>
                  </li>
                ))
              ) : (
                <li>No data found</li>
              )}
            </ul>
          )
        )}
      </div>
    </div>
  );
};

export default Component;
