import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import "./Component.css";

export const AmenityMultiCheck = ({ disabled, options, selected = [], category, onCheck, optionName = "name" }) => {
  const handleCheck = (item) => {
    console.log("checkkk", item);
    console.log("categoryyyy", category);
    if (selected.includes(item._id))
      onCheck(
        category,
        selected.filter((slc) => slc !== item._id)
      );
    else onCheck(category, [...selected, item._id]);
  };
  return (
    <div className='amenitiesWrapper'>
      {options.map((item, i) => (
        <label key={i} className={"amenity-check-label " + (selected.includes(item._id) ? " selected" : " normal")} style={{ border: "2px solid" }}>
          <span>
            <input disabled={disabled} type='checkbox' onChange={() => handleCheck(item)} checked={selected.includes(item._id)} />
            <span>{selected.includes(item._id) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</span>
            {item[optionName]}
          </span>
          <span className='w-full'>{item.description}</span>
        </label>
      ))}
    </div>
  );
};
