import { Link } from "react-router-dom";
// import "./component.css";

const Component = () => {
  return (
    <>
      <div className='my-top'>
        <div className='container'>
          {/* <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center> */}
          <div className='row feature-top02'>
            <div className='col-lg-6'>
              <center>
                <img src='img/locations.png' className='img-fluid feature-img2' alt='Location' />
              </center>
            </div>
            <div className='col-lg-6'>
              <div className='feature-padd2'>
                <p className='stay-txt2'>Locations & Properties Management</p>
                {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
                <div className='desk-top-2'>
                  <div className='stay-feature1-flx'>
                    <div className='flx2'>
                      <i className='ri-bard-line feature-icn'></i>
                    </div>
                    <div className='flx3'>
                      {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                      <p className='feature1-txt2'>Centralized dashboard for overseeing multiple locations effortlessly.</p>
                    </div>
                  </div>
                  <div className='stay-feature1-flx'>
                    <div className='flx2'>
                      <i className='ri-bard-line feature-icn'></i>
                    </div>
                    <div className='flx3'>
                      {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                      <p className='feature1-txt2'>Flexible room allocation and inventory management across all properties.</p>
                    </div>
                  </div>
                  <div className='stay-feature1-flx'>
                    <div className='flx2'>
                      <i className='ri-bard-line feature-icn'></i>
                    </div>
                    <div className='flx3'>
                      {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                      <p className='feature1-txt2'>Dynamic mapping tools for visualizing property distribution.</p>
                    </div>
                  </div>
                  <div className='stay-feature1-flx'>
                    <div className='flx2'>
                      <i className='ri-bard-line feature-icn'></i>
                    </div>
                    <div className='flx3'>
                      {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                      <p className='feature1-txt2'>Customizable alerts for maintenance and service requests.</p>
                    </div>
                  </div>
                  <div className='stay-feature1-flx'>
                    <div className='flx2'>
                      <i className='ri-bard-line feature-icn'></i>
                    </div>
                    <div className='flx3'>
                      {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                      <p className='feature1-txt2'>Guest feedback management system for continuous improvement</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
