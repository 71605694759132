// import Topbar from "../components/topbar/Component.js";
import Nav from "../components/nav/Component.js";
import Login from "../components/login/Component.js";
import Nav2 from "../components/nav2/Component.js";
import Footer2 from "../components/footer2/Component.js";

import { Helmet } from "react-helmet";

import "./../css/style.css";

const Component = () => {
  return (
    <>
      <Helmet>
        <title>Login - StayStation</title>
      </Helmet>
      {/* <Nav2/> */}
      <Login />
      {/* <Footer2 /> */}
    </>
  );
};

export default Component;
