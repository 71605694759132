import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { toast } from "react-toastify";
import "./component.css";
import { Link, useNavigate } from "react-router-dom";
import SearchableSelectComponentHotel from "../../support_ui/hotel_client_select/Component.js";
function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [hotelName, setHotelName] = useState("");

  const [subscriptionRoom, setSubscriptionRoom] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");

  const [totalAmount, setTotalAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [clientList, setClientList] = useState([]);

  const [hotelList, setHotelList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  // useEffect(() => {
  //   if (clientName) {
  //     fetchHotelData();
  //   }
  // }, [clientName]);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setHotelList(data.Data);
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const handleClientNameChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const clientId = selectedOption.dataset.clientId;
    setHotelName(event.target.value);
    setClientName(clientId);
  };
  // const handleHotelNameChange = (event) => { setHotelName(event.target.value); };

  const handleSubscriptionNameChange = (event) => {
    setSubscriptionName(event.target.value);
  };
  const handleSubscriptionRoomChange = (event) => {
    setSubscriptionRoom(event.target.value);
  };
  const handleSubscriptionValidityChange = (event) => {
    setSubscriptionValidity(event.target.value);
  };
  const handleTotalAmountChange = (event) => {
    setTotalAmount(event.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!hotelName.trim()) {
      errors.hotelName = "Hotel is required";
    }
    if (!subscriptionName.trim()) {
      errors.subscriptionName = "Subscription Name is required";
    }
    // if (!clientName.trim()) { errors.clientName = 'Client is required'; }
    if (!totalAmount.trim()) {
      errors.totalAmount = "Price is required";
    }
    if (!subscriptionRoom.trim()) {
      errors.subscriptionRoom = "Room is required";
    }
    if (!subscriptionValidity.trim()) {
      errors.subscriptionValidity = "Validity is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (isSubmitting) return;
    setIsSubmitting(true);
    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.subscription_add, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            client_id: clientName,
            hotel_id: hotelName,
            subscription_name: subscriptionName,
            subscription_rooms: subscriptionRoom,
            subscription_validity: subscriptionValidity,
            total_amount: totalAmount,
          }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          setClientName("");
          setHotelName("");
          setSubscriptionRoom("");
          setSubscriptionValidity("");
          setIsModalOpen(false);
          toast.success(responseData.Message);
          onRefresh();
        } else {
          toast.error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body '>
                  <div className='row'>
                    <div className='mb-3'>
                      <label htmlFor='hotelName' className='col-form-label'>
                        Hotel Name:
                      </label>
                      <SearchableSelectComponentHotel
                        className='w-100'
                        setClientName={setClientName}
                        setHotelName={setHotelName}
                        searchValueProps={{ searchValue, setSearchValue }}
                        suggestionsProps={{ suggestions, setSuggestions }}
                        showDropdownProps={{ showDropdown, setShowDropdown }}
                        form={false}
                      />
                      {/* {errors.hotelName && <div className="invalid-feedback">{errors.hotelName}</div>} */}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='subscriptionName' className='col-form-label'>
                        Subscription Name:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.subscriptionName ? "is-invalid" : ""}`}
                        id='subscriptionName'
                        value={subscriptionName}
                        onChange={handleSubscriptionNameChange}
                      />
                      {errors.subscriptionName && <div className='invalid-feedback'>{errors.subscriptionName}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='subscriptionRoom' className='col-form-label'>
                        Subscription Rooms:
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.subscriptionRoom ? "is-invalid" : ""}`}
                        id='subscriptionRoom'
                        value={subscriptionRoom}
                        onChange={handleSubscriptionRoomChange}
                      />
                      {errors.subscriptionRoom && <div className='invalid-feedback'>{errors.subscriptionRoom}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='subscriptionValidity' className='col-form-label'>
                        Subscription Validity:
                      </label>
                      <input
                        type='date'
                        className={`form-control ${errors.subscriptionValidity ? "is-invalid" : ""}`}
                        id='subscriptionValidity'
                        value={subscriptionValidity}
                        onChange={handleSubscriptionValidityChange}
                      />
                      {errors.subscriptionValidity && <div className='invalid-feedback'>{errors.subscriptionValidity}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='totalAmount' className='col-form-label'>
                        Total Amount:
                      </label>
                      <input type='number' className={`form-control ${errors.totalAmount ? "is-invalid" : ""}`} id='totalAmount' value={totalAmount} onChange={handleTotalAmountChange} />
                      {errors.totalAmount && <div className='invalid-feedback'>{errors.totalAmount}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                    Close
                  </button>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
