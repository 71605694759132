import { Link } from "react-router-dom";
// import "./component.css";

const Component = () => {
  return (
    <>
    <div className="my-top">
    <div className="container">
    {/* <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center> */}
    <div className="row feature-top02">
      <div className="col-lg-6">
        <center><img src="img/analytics.png" className="img-fluid feature-img2" alt="Analytics" /></center>
      </div>
      <div className="col-lg-6">
        <div className="feature-padd2">
          <p className="stay-txt2">Analytics & Support Management</p>
          {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
          <div className="desk-top-2">
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Real-time performance monitoring for proactive decision-making.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Unlock the power of custom reports and analytics across all modules.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Ticketing system for efficient issue tracking and resolution.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Integrated live chat for real-time communication with support agents.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Automated notifications for status updates and resolution progress.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </>

);
};

export default Component;
