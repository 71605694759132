import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import SearchableSelectComponentClient from "../client_select/Component.js";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [accountType, setAccountType] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For Select Dropdown
  const [searchValue, setSearchValue] = useState("Select Client");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setClientList(data.Data);
    } catch (error) {
      toast.error(error);
      navigate(`${admin_prefix}login`);
    }
  };

  // const handleClientNameChange = (event) => { setClientName(event.target.value); };
  const handleAccountType = (event) => {
    setAccountType(event.target.value);
  };
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState({
    hotelName: "",
    companyName: "",
    gstNumber: "",
    urlName: "",
    domain: "",
    address: "",
    subscriptionName: "",
    price: "",
    subscriptionRoom: "",
    subscriptionValidity: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleNextStep = () => {
    if (validateForm()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (activeStep === 1) {
      // if (!clientName.trim() != '') { errors.clientName = 'Client is required'; }
      if (!clientName.trim() != "") {
        alert("Client is required");
        errors.clientName = "Client is required";
        isValid = false;
      }
      if (!formData.hotelName.trim()) {
        errors.hotelName = "Hotel Name is required";
        isValid = false;
      }
      if (!formData.companyName.trim()) {
        errors.companyName = "Company Name is required";
        isValid = false;
      }
      // if (!formData.gstNumber.trim()) { errors.gstNumber = 'GST Number is required'; isValid = false; }
      if (!formData.urlName.trim()) {
        errors.urlName = "UrlName Name is required";
        isValid = false;
      }
      if (!formData.address.trim()) {
        errors.address = "Address is required";
        isValid = false;
      }
    }

    if (activeStep === 2) {
      if (!formData.subscriptionName.trim()) {
        errors.subscriptionName = "Subscription Name is required";
        isValid = false;
      }
      if (!formData.price.trim()) {
        errors.price = "Price is required";
        isValid = false;
      }
      if (!formData.subscriptionRoom.trim()) {
        errors.subscriptionRoom = "Room is required";
        isValid = false;
      }
      if (!formData.subscriptionValidity.trim()) {
        errors.subscriptionValidity = "Validity is required";
        isValid = false;
      }
    }

    setErrors(errors);
    toast.error(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validateForm() && clientName.trim() != "") {
      try {
        const response_hotel = await fetch(url + adminpoints.hotel_add, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            client_id: clientName,
            account_type: accountType,
            hotel_name: formData.hotelName,
            urlName: formData.urlName,
            domain: formData.domain,
            billing_detail: {
              legal_company: formData.companyName,
              billing_address: formData.address,
              gstn: formData.gstNumber,
            },
          }),
        });

        if (!response_hotel.ok) {
          navigate(`${admin_prefix}login`);
        }

        const hotelData = await response_hotel.json();
        const hotelID = hotelData.Data[0]._id;
        // Hotel Add API

        // Subscription Add API
        const response_subscription = await fetch(url + adminpoints.subscription_add, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            client_id: clientName,
            hotel_id: hotelID,
            subscription_name: formData.subscriptionName,
            subscription_rooms: formData.subscriptionRoom,
            subscription_validity: formData.subscriptionValidity,
            total_amount: formData.price,
          }),
        });

        const subscriptionData = await response_subscription.json();

        if (subscriptionData.Status) {
          setFormData({
            hotelName: "",
            companyName: "",
            gstNumber: "",
            address: "",
            subscriptionName: "",
            price: "",
            urlName: "",
            domain: "",
            subscriptionRoom: "",
            subscriptionValidity: "",
          });
          setClientName("");
          setAccountType("");
          toast.success(subscriptionData.Message);
        } else {
          toast.error(subscriptionData.Message);
        }
        onRefresh();
        setActiveStep(1);
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
      setIsSubmitting(false);
    }
  };

  const checkExistsInDataBase = async (key, value, msg) => {
    try {
      const response = await fetch(url + adminpoints.hotel_count, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ filters: { [key]: value } }),
        credentials: "include",
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exist!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  const handleUrlNameBlur = async () => {
    const urlName = formData.urlName.trim();
    if (urlName) {
      const exists = await checkExistsInDataBase("urlName", urlName, "URL Name");
      if (exists) {
        setErrors({ ...errors, urlName: "URL name already exists" });
      } else {
        setErrors({ ...errors, urlName: "" });
      }
    }
  };

  const handleDomainBlur = async () => {
    const domain = formData.domain.trim();
    if (domain) {
      const exists = await checkExistsInDataBase("domain", domain, "Domain Name");
      if (exists) {
        setErrors({ ...errors, domain: "Domain name already exists" });
      } else {
        setErrors({ ...errors, domain: "" });
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      clientName: "",
      hotelName: "",
      subscriptionName: "",
      price: "",
      subscriptionRoom: "",
      subscriptionValidity: "",
      companyName: "",
      urlName: "",
      domain: "",
      gstNumber: "",
      address: "",
    });
    setAccountType("");
    setClientName("");
    setActiveStep(1);
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='tabs-container mb-3  justify-content-start'>
                    <div className={`tab ${activeStep === 1 ? "active" : ""}`} onClick={() => handleStepChange(1)}>
                      General
                    </div>
                    <div
                      className={`tab ${activeStep === 2 ? "active" : ""}`}
                      onClick={() => {
                        handleStepChange(2);
                        handleUrlNameBlur();
                        handleDomainBlur();
                      }}
                    >
                      Subscription
                    </div>
                  </div>
                  <div className={activeStep === 1 ? "" : "d-none"}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='clientName' className='col-form-label'>
                          Select Client:
                        </label>
                        {/* <SearchableSelectComponentClient
                          id="clientName"
                          setClientName={setClientName}
                          searchValueProps={{ searchValue, setSearchValue }}
                          suggestionsProps={{ suggestions, setSuggestions }}
                          showDropdownProps={{ showDropdown, setShowDropdown }}
                          form={false} /> */}

                        <div className='nextd'>
                          <SearchableSelectComponentClient
                            setClientName={setClientName}
                            searchValueProps={{ searchValue, setSearchValue }}
                            suggestionsProps={{ suggestions, setSuggestions }}
                            showDropdownProps={{ showDropdown, setShowDropdown }}
                            form={false}
                          />
                        </div>

                        {errors.clientName && <div className='invalid-feedback'>{errors.clientName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='accountType' className='col-form-label'>
                          Account Type:
                        </label>
                        <select id='accountType' className={`form-select ${errors.accountType ? "is-invalid" : ""}`} value={accountType} onChange={handleAccountType}>
                          <option value='true'>Client</option>
                          <option value='false'>Demo</option>
                        </select>
                        {errors.accountType && <div className='invalid-feedback'>{errors.accountType}</div>}
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="urlName" className="col-form-label">URL Name:</label>
                        <input type="text" className={`form-control ${errors.urlName ? 'is-invalid' : ''}`} id="urlName" name='urlName' value={formData.urlName} onChange={handleChange} />
                        {errors.urlName && <div className="invalid-feedback">{errors.urlName}</div>}
                      </div> */}
                      <div className='col-md-6'>
                        <label htmlFor='urlName' className='col-form-label'>
                          URL Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.urlName ? "is-invalid" : ""}`}
                          id='urlName'
                          name='urlName'
                          value={formData.urlName}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!/\s/.test(value)) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleUrlNameBlur}
                        />
                        {errors.urlName && <div className='invalid-feedback'>{errors.urlName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='domain' className='col-form-label'>
                          Domain Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.domain ? "is-invalid" : ""}`}
                          id='domain'
                          value={formData.domain}
                          name='domain'
                          onChange={handleChange}
                          onBlur={handleDomainBlur}
                        />
                        {errors.domain && <div className='invalid-feedback'>{errors.domain}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='hotelName' className='col-form-label'>
                          Hotel / Brand Name
                        </label>
                        <input type='text' className={`form-control ${errors.hotelName ? "is-invalid" : ""}`} id='hotelName' name='hotelName' value={formData.hotelName} onChange={handleChange} />
                        {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='companyName' className='col-form-label'>
                          Company Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                          id='companyName'
                          name='companyName'
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                        {errors.companyName && <div className='invalid-feedback'>{errors.companyName}</div>}
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='gstNumber' className='col-form-label'>
                          GST Number:
                        </label>
                        <input type='text' className={`form-control`} id='gstNumber' value={formData.gstNumber} name='gstNumber' onChange={handleChange} />
                        {/* {errors.gstNumber && <div className="invalid-feedback">{errors.gstNumber}</div>} */}
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='address' className='col-form-label'>
                          Address:
                        </label>
                        <input type='text' className={`form-control ${errors.address ? "is-invalid" : ""}`} id='address' name='address' value={formData.address} onChange={handleChange} />
                        {errors.address && <div className='invalid-feedback'>{errors.address}</div>}
                      </div>
                    </div>
                  </div>
                  <div className={activeStep === 2 ? "" : "d-none"}>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionName' className='col-form-label'>
                          Subscription Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.subscriptionName ? "is-invalid" : ""}`}
                          id='subscriptionName'
                          name='subscriptionName'
                          value={formData.subscriptionName}
                          onChange={handleChange}
                        />
                        {errors.subscriptionName && <div className='invalid-feedback'>{errors.subscriptionName}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionRoom' className='col-form-label'>
                          Subscription Rooms:
                        </label>
                        <input
                          type='number'
                          className={`form-control ${errors.subscriptionRoom ? "is-invalid" : ""}`}
                          id='subscriptionRoom'
                          name='subscriptionRoom'
                          value={formData.subscriptionRoom}
                          onChange={handleChange}
                        />
                        {errors.subscriptionRoom && <div className='invalid-feedback'>{errors.subscriptionRoom}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='price' className='col-form-label'>
                          Subscription Amount:
                        </label>
                        <input type='number' className={`form-control ${errors.price ? "is-invalid" : ""}`} id='price' value={formData.price} name='price' onChange={handleChange} />
                        {errors.price && <div className='invalid-feedback'>{errors.price}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionValidity' className='col-form-label'>
                          Subscription Validity:
                        </label>
                        <input
                          type='date'
                          className={`form-control ${errors.subscriptionValidity ? "is-invalid" : ""}`}
                          id='subscriptionValidity'
                          name='subscriptionValidity'
                          value={formData.subscriptionValidity}
                          onChange={handleChange}
                        />
                        {errors.subscriptionValidity && <div className='invalid-feedback'>{errors.subscriptionValidity}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  {activeStep > 1 && (
                    <button type='button' className='btn btn-secondary' onClick={handlePrevStep}>
                      Previous
                    </button>
                  )}
                  {activeStep < 2 && (
                    <button type='button' className='btn btn-primary' onClick={handleNextStep}>
                      Next
                    </button>
                  )}
                  {activeStep === 2 && (
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {isSubmitting ? "Loading..." : "Submit"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
