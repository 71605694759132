import React, { useState, useEffect } from "react";
import { clientpoints, url, clientHeader } from "../../../../../../lib/lib.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import SearchableLocationSelect from "../location_select/Component.js";
import SearchableFloorSelect from "../floor_select/Component.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const { hotelId } = useParams();
  const [additionalFields, setAdditionalFields] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let clientId = data;

  const [locationName, setLocationName] = useState("");
  const [searchValueLocation, setSearchValueLocation] = useState("Select Location");
  const [suggestionsLocation, setSuggestionsLocation] = useState([]);
  const [showDropdownLocation, setShowDropdownLocation] = useState("Select Location");

  const [floorName, setFloorName] = useState("");
  const [searchValueFloor, setSearchValueFloor] = useState("Select Floor");
  const [suggestionsFloor, setSuggestionsFloor] = useState([]);
  const [showDropdownFloor, setShowDropdownFloor] = useState("Select Floor");

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    if (!locationName.trim()) {
      alert("Location is required");
      errors.locationName = "Location is required";
      isValid = false;
    }
    if (!floorName.trim()) {
      alert("Floor is required");
      errors.floorName = "Floor is required";
      isValid = false;
    }
    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(clientId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, clientId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.event_view, {
        method: "POST",
        headers: clientHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          title: response_data.title,
          description: response_data.description,
        });
        setLocationName(response_data.location_id._id);
        setSearchValueLocation(response_data.location_id.location);
        setShowDropdownLocation(response_data.location_id.location);
        setFloorName(response_data.floor_id._id);
        setSearchValueFloor(response_data.floor_id.floor_name);
        setShowDropdownFloor(response_data.floor_id.floor_name);
        setAdditionalFields(response_data.question);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.event_edit, {
          method: "POST",
          headers: clientHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { _id: current_id, hotel_id: hotelId },
            data: {
              title: formData.title,
              description: formData.description,
              question: additionalFields,
              location_id: locationName,
              floor_id: floorName,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const addField = () => {
    setAdditionalFields([...additionalFields, ""]);
  };

  const removeField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  const handleChangeDynamicFields = (event, index) => {
    const { value } = event.target;
    const updatedFields = [...additionalFields];
    updatedFields[index] = value;
    setAdditionalFields(updatedFields);
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='Location' className='col-form-label'>
                          Location
                        </label>
                        <SearchableLocationSelect
                          setLocationName={setLocationName}
                          searchValueProps={{ searchValueLocation, setSearchValueLocation }}
                          suggestionsProps={{ suggestionsLocation, setSuggestionsLocation }}
                          showDropdownProps={{ showDropdownLocation, setShowDropdownLocation }}
                          form={false}
                          setFloorName={setFloorName}
                          setSearchValueFloor={setSearchValueFloor}
                          setShowDropdownFloor={setShowDropdownFloor}
                        />
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='Location' className='col-form-label'>
                          Floor
                        </label>
                        <SearchableFloorSelect
                          setFloorName={setFloorName}
                          searchValueProps={{ searchValueFloor, setSearchValueFloor }}
                          suggestionsProps={{ suggestionsFloor, setSuggestionsFloor }}
                          showDropdownProps={{ showDropdownFloor, setShowDropdownFloor }}
                          form={false}
                          locationName={locationName}
                        />
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='title' className='col-form-label'>
                          Title
                        </label>
                        <input type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} id='title' name='title' value={formData.title} onChange={handleChange} />
                        {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='description' className='col-form-label'>
                          Description
                        </label>
                        <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                        {errors.description && <div className='invalid-feedback'> {errors.description}</div>}
                      </div>

                      {/* {additionalFields.map((question, index) => (
                        <div key={index} className="form-group">
                          <label htmlFor={`additionalField${index}`}>{question}</label>
                        </div>
                      ))} */}

                      {additionalFields.map((question, index) => (
                        <div key={index} className='col-md-12 mb-3'>
                          <label htmlFor={`additionalField${index}`} className='col-form-label'>
                            Question {index + 1}
                          </label>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control'
                              id={`additionalField${index}`}
                              name={`additionalField${index}`}
                              value={question}
                              onChange={(event) => handleChangeDynamicFields(event, index)}
                            />
                            <button
                              type='button'
                              className='btn btn-danger'
                              style={{
                                position: "static",
                                height: "fit-content",
                                padding: "8px",
                              }}
                              onClick={() => removeField(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}

                      <div className='col-md-12'>
                        <button type='button' className='btn btn-success' onClick={addField}>
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      {" "}
                      Close{" "}
                    </button>
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {" "}
                      {isSubmitting ? "Loading..." : "Update"}{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
