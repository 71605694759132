import Coupons from "../../components/offers/coupons/table/Component";
import { toast, ToastContainer } from "react-toastify";
export default function Component() {
  return (
    <>
      <ToastContainer />
      <Coupons name='Coupons' />
    </>
  );
}
