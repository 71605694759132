import { url, clientpoints, clientHeader } from "../../../../../../lib/lib.js";
import React, { useRef, useState, useEffect } from "react";
import "./cards.css";
import { toast } from "react-toastify";
import PostCommentComponent from "../post_comment/Component.js";

function Card1({ data, setSelectedItem, setIsModalOpenEdit, setIsDeleteModal }) {
  const [status, setStatus] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(url + clientpoints.post_like_count, {
        method: "POST",
        headers: clientHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { post_id: data._id } }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const postLike = async (postId) => {
    setStatus((prevStatus) => !prevStatus);
    let temp_status;
    if (status == true) {
      temp_status = false;
    } else {
      temp_status = true;
    }
    try {
      const response = await fetch(url + clientpoints.post_like_add, {
        method: "POST",
        headers: clientHeader,
        credentials: "include",
        body: JSON.stringify({ status: temp_status, post_id: postId, user_type: "client" }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }
      console.log("====================================");
      console.log("ss", data);
      console.log("====================================");
      if (data.Status) {
        toast.success(data.Message);
      } else {
        toast.error(data.Message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const PostLikeHandle = (id) => {
    postLike(id);
  };

  const [postItem, setPostItem] = useState(null);
  const [clientItem, setClientItem] = useState(null);
  const [hotelItem, setHotelItem] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const PostCommentHandle = (item) => {
    setPostItem(item._id);
    setClientItem(item.client_id);
    setHotelItem(item.hotel_id);
    setCreatedAt(item.createdAt);
    setIsChatOpen(true);
  };

  return (
    <>
      <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
        <div className='post-card'>
          <div class='post-sideline1'></div>
          <img src='/img/post.png' className='img-fluid post-image' />
          <div className='row over-dropdown'>
            <div className='col-12'>
              <div class='droptop '>
                <div type='button' class='show'>
                  <i className='ri-more-2-line more-detail'></i>
                  <div className='cabin-editicn'>
                    <i
                      class='ri-pencil-line pencil-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsModalOpenEdit(true);
                      }}
                    ></i>
                    <i
                      class='ri-delete-bin-6-line trash-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsDeleteModal(true);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='post-inner-txt'>
            <p class='posts-title'>{data.title && data.title}</p>
            <p class='posts-txt'>{data.description && data.description}</p>
            <div className='posts-public'>
              <div
                className='comment'
                onClick={() => {
                  PostLikeHandle(data._id);
                }}
              >
                <i class='ri-heart-3-line likeicn'></i> <p className='postcount'>1000</p>
              </div>
              <div
                className='comment'
                onClick={() => {
                  PostCommentHandle(data);
                }}
              >
                <i class='ri-chat-1-line likeicn'></i> <p className='postcount'>99+</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isChatOpen && (
        <PostCommentComponent isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} createdAt={createdAt} clientItem={clientItem} hotelItem={hotelItem} postItem={postItem} modalName={"Comment"} />
      )}
    </>
  );
}

export default Card1;
