import React, { useMemo, useState, useEffect, useRef } from "react";
import { url, clientpoints, clientHeader } from "../../../../../../lib/lib.js";
import useScrollBlock from "../../../../utils/useScrollBlock";
import "../../../../../nav/components/location/component.css";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import PostCard from "../card/Cards.js";
import AddModalComponent from "../add_modal/Component.js";
import EditModalComponent from "../edit_modal/Component.js";

const Component = ({ name = "All" }) => {
  const inputRef = useRef(null);
  const { hotelId } = useParams();

  // --------All States Start
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  // For Search Filters Data
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);

  // For Loading
  const [isLoading, setIsLoading] = useState(false);

  // Search Related
  const [selectedItem, setSelectedItem] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  // For Modal Scroll
  const disableScroll = useScrollBlock();

  // For Search Related
  const [isExpanded, setIsExpanded] = useState(false);

  // For Add Related
  const [isAddModal, setIsAddModal] = useState(false);

  // For Delete Related
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // For pagination
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });
  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  //For Add Modal
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);

  //For Edit Modal
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);

  // --------All States End

  // --------All UseEffect Start
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  // useEffect(() => {
  //   disableScroll(isAddModal || isModalOpenEdit || isDeleteModal);
  // }, [isAddModal, isModalOpenEdit, isDeleteModal]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    fetchData();
  }, []);

  // --------All UseEffect End

  // --------All handlers Start
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: currentPageIndex,
    }));
    fetchData();
  };

  const handleSearchClose = () => {
    setSearchTerm("");
    setIsExpanded(false);
    setShowFilterMsg(false);
    fetchData();
  };

  const handleSearchExpand = () => {
    setIsExpanded(true);
  };

  const handleRefresh = () => {
    fetchData();
  };
  // --------All handlers End

  // --------All Main functions Start
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let obj = {
        filters: {
          ...(hotelId && { hotel_id: hotelId }),
        },
      };

      const requestBody = {
        ...obj,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.event_view, {
        method: "POST",
        headers: clientHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // --------All Main functions End

  // --------Hard Refresh Function Start
  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: 1,
        size: 12,
        filters: { hotel_id: hotelId },
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.event_view, {
        method: "POST",
        headers: clientHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setData(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // --------Hard Refresh Function End

  // --------Page Function Start
  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} ${filterText} entries`;
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };
  // --------Page Function End

  // --------Delete Function Start
  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if (deleteConfirm == type) {
      try {
        const response = await fetch(url + clientpoints.event_delete, {
          method: "POST",
          headers: clientHeader,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: deleteItemId } }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error("Failed to delete item");
        }

        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        handleRefresh();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    } else {
      toast.error("Not Matched");
    }
  };
  // --------Delete Function End

  if (isDeleteModal) {
    handleDelete(selectedItem._id, selectedItem.title);
    setIsDeleteModal(false);
  }

  return (
    <>
      <ToastContainer />

      {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add Event"} onRefresh={handleRefresh} />}
      {isModalOpenEdit && <EditModalComponent isModalOpen={isModalOpenEdit} setIsModalOpen={setIsModalOpenEdit} data={selectedItem} modalName={"Edit Event"} onRefresh={handleRefresh} />}

      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>{name}</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input
                ref={inputRef}
                className={`search-bar ${isExpanded ? "expanded" : ""}`}
                type='text'
                placeholder='Search roles'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={handleSearchSubmit}
              />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  {" "}
                  <i className='ri-search-line'></i>{" "}
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  {" "}
                  <i className='ri-close-line'></i>{" "}
                </button>
              )}
            </div>
            <button className='circle-btn-add icon' onClick={() => setIsModalOpenAdd(true)}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>

        <div className='d-flex mb-4'>
          <button onClick={handleRefresh} className='btn btn-primary'>
            {" "}
            {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}&#160; Refresh{" "}
          </button>
          &#160;&nbsp;&nbsp;
          <button className='btn btn-primary' onClick={handleHardRefresh}>
            <i className='ri-gradienter-line'></i> Reset
          </button>
          &#160;&nbsp;&nbsp;
        </div>

        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center myload'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='row'>
              {data?.map((item) => (
                <PostCard data={item} setSelectedItem={setSelectedItem} setIsModalOpenEdit={setIsModalOpenEdit} setIsDeleteModal={setIsDeleteModal} />
              ))}
            </div>

            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <center className='d-flex justify-content-center align-items-center myload'>
            <div className='nodatafound1'>No data found</div>
          </center>
        )}
      </div>
    </>
  );
};

export default Component;
