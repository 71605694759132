import { Link } from "react-router-dom";
// import "./component.css";

const Component = () => {
  return (
    <>
    <div className="my-top">
    <div className="container">
    {/* <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center> */}
    <div className="row feature-top02">
      <div className="col-lg-6">
        <center><img src="img/expense.png" className="img-fluid feature-img2" alt="Expense"/></center>
      </div>
      <div className="col-lg-6">
        <div className="feature-padd2">
          <p className="stay-txt2">Expense & Inventory Management</p>
          {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
          <div className="desk-top-2">
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Centralized expense tracking for efficient financial management.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Customizable expense categories and budgets for precise control.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Real-time inventory monitoring to optimize stock levels and reduce waste.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Alerts and notifications for low stock levels and expiring inventory.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Customized reports for tailored expense and inventory insights.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </>

);
};

export default Component;
