import React from "react";

const Component = () => {
  return (
    <>
      <h1>Hotels</h1>
    </>
  );
};

export default Component;
