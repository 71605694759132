import AllClients from "../../components/client/all_clients/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <AllClients name='All Clients' />
    </>
  );
}
