import React, { useState } from "react";
import { adminpoints, url, admin_prefix, adminHeader } from "../../../../../lib/lib.js";
import "./component.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [accountType, setAccountType] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    hotelName: "",
    companyName: "",
    gstNumber: "",
    address: "",
    urlName: "",
    domain: "",
    subscriptionName: "",
    subscriptionRooms: "",
    price: "",
    subscriptionValidity: "",
  });

  // Form Submit State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingError, setIsSubmittingError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleNextStep = () => {
    if (validateForm()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (activeStep === 1) {
      if (!formData.name.trim()) {
        errors.name = "Name is required";
        isValid = false;
      }
      if (!formData.email.trim()) {
        errors.email = "Email is required";
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = "Invalid email address";
        isValid = false;
      }
      if (!formData.phone.trim()) {
        errors.phone = "Phone is required";
        isValid = false;
      } else if (!/^\d{10}$/.test(formData.phone)) {
        errors.phone = "Phone number must be 10 digits";
        isValid = false;
      }
    }

    if (activeStep === 2) {
      if (!formData.hotelName.trim()) {
        errors.hotelName = "Hotel Name is required";
        isValid = false;
      }
      if (!formData.companyName.trim()) {
        errors.companyName = "Company Name is required";
        isValid = false;
      }
      if (!formData.urlName.trim()) {
        errors.urlName = "UrlName Name is required";
        isValid = false;
      }
      if (!formData.address.trim()) {
        errors.address = "Address is required";
        isValid = false;
      }
    }

    if (activeStep === 3) {
      if (!formData.subscriptionName.trim()) {
        errors.subscriptionName = "Subscription Name is required";
        isValid = false;
      }
      if (!formData.price.trim()) {
        errors.price = "Price is required";
        isValid = false;
      }
      if (!formData.subscriptionRooms.trim()) {
        errors.subscriptionRooms = "Room is required";
        isValid = false;
      }
      if (!formData.subscriptionValidity.trim()) {
        errors.subscriptionValidity = "Validity is required";
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const handleAccountType = (event) => {
    setAccountType(event.target.value);
  };

  const submitFormData = async () => {
    try {
      // Client Add API
      const response = await fetch(url + adminpoints.client_add, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
        }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const partialData = await response.json();
      const clientID = partialData.Data[0]._id;
      // Client Add API

      // Hotel Add API
      const response_hotel = await fetch(url + adminpoints.hotel_add, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({
          client_id: clientID,
          hotel_name: formData.hotelName,
          account_type: accountType,
          urlName: formData.urlName,
          domain: formData.domain,
          billing_detail: {
            legal_company: formData.companyName,
            billing_address: formData.address,
            gstn: formData.gstNumber,
          },
        }),
      });

      const hotelData = await response_hotel.json();
      const hotelID = hotelData.Data[0]._id;
      // Hotel Add API

      // Subscription Add API
      const response_subscription = await fetch(url + adminpoints.subscription_add, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({
          client_id: clientID,
          hotel_id: hotelID,
          subscription_name: formData.subscriptionName,
          subscription_rooms: formData.subscriptionRooms,
          subscription_validity: formData.subscriptionValidity,
          total_amount: formData.price,
        }),
      });

      const subscriptionData = await response_subscription.json();
      if (subscriptionData.Status) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          hotelName: "",
          companyName: "",
          gstNumber: "",
          address: "",
          urlName: "",
          domain: "",
          subscriptionName: "",
          subscriptionRooms: "",
          price: "",
          subscriptionValidity: "",
        });
        toast.success(subscriptionData.Message);
      } else {
        toast.success(hotelData.Message);
      }

      onRefresh();
      setActiveStep(1);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error.message);
      navigate(`${admin_prefix}login`);
    }
  };

  // Form Submit Handle
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validateForm()) {
      try {
        const emailResponse = await fetch(url + adminpoints.client_view, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { email: formData.email, delete_status: "" },
          }),
        });

        if (!emailResponse.ok) {
          navigate(`${admin_prefix}login`);
        }

        const emailData = await emailResponse.json();

        if (emailData.Status && emailData.Data.length > 0) {
          if (emailData.Data[0].email === formData.email) {
            toast.error("Email already exists in the database.");
            return;
          }
        }

        // Check URL name
        const URLNameResponse = await fetch(url + adminpoints.hotel_view, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { urlName: formData.urlName, delete_status: "" },
          }),
        });

        if (!URLNameResponse.ok) {
          navigate(`${admin_prefix}login`);
        }

        const URLData = await URLNameResponse.json();

        if (URLData.Status && URLData.Data.length > 0) {
          if (URLData.Data[0].urlName === URLData.urlName) {
            toast.error("URL name already exist.");
            return;
          }
        }
        // Check Url name

        // Check if phone exists
        const phoneResponse = await fetch(url + adminpoints.client_view, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { phone: formData.phone },
          }),
        });

        if (!phoneResponse.ok) {
          navigate(`${admin_prefix}login`);
        }

        const phoneData = await phoneResponse.json();

        if (phoneData.Status) {
          if (phoneData.Data && phoneData.Data.length > 0) {
            const existingPhone = phoneData.Data[0].phone;
            if (existingPhone === formData.phone) {
              toast.error("Phone already exists in the database.");
              return;
            }
          }
        } else {
          toast.error("Failed to check phone existence in the database.");
          return;
        }

        if (formData.phone.length < 10) {
          toast.error("Phone number should be at least 10 digits long.");
          return;
        }

        await submitFormData();
      } catch (error) {
        toast.error("Failed to submit form data.", error);
        navigate(`${admin_prefix}login`);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      hotelName: "",

      subscriptionName: "",
      price: "",
      subscriptionRooms: "",
      subscriptionValidity: "",
      companyName: "",
      gstNumber: "",
      address: "",
    });
    setActiveStep(1);
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      console.log("endpoint", adminpoints[table]);
      const response = await fetch(url + adminpoints[table], {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ filters: { [key]: value, delete_status: { $in: [true, false] } } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exists!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  const handleEmailBlur = async () => {
    const email = formData.email.trim();
    if (email) {
      const exists = await checkExistsInDataBase("email", email, "Email", "client_count");
      if (exists) {
        setErrors({ ...errors, email: "Email Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, email: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handlePhoneBlur = async () => {
    const phone = formData.phone.trim();
    if (phone) {
      const exists = await checkExistsInDataBase("phone", phone, "phone", "client_count");
      if (exists) {
        setErrors({ ...errors, phone: "Phone already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, phone: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handleUrlNameBlur = async () => {
    const urlName = formData.urlName.trim();
    if (urlName) {
      const exists = await checkExistsInDataBase("urlName", urlName, "URL Name", "hotel_count");
      if (exists) {
        setErrors({ ...errors, urlName: "URL name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, urlName: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handleDomainBlur = async () => {
    const domain = formData.domain.trim();
    if (domain) {
      const exists = await checkExistsInDataBase("domain", domain, "Domain Name", "hotel_count");
      if (exists) {
        setErrors({ ...errors, domain: "Domain name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, domain: "" });
        setIsSubmittingError(false);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <div className='modal-body'>
                <div className='tabs-container mb-3  justify-content-start'>
                  <div className={`tab ${activeStep === 1 ? "active" : ""}`} onClick={() => handleStepChange(1)}>
                    General
                  </div>
                  <div
                    className={`tab ${activeStep === 2 ? "active" : ""}`}
                    onClick={() => {
                      handleStepChange(2);
                      handleEmailBlur();
                      handlePhoneBlur();
                    }}
                  >
                    Hotel
                  </div>
                  <div
                    className={`tab ${activeStep === 3 ? "active" : ""}`}
                    onClick={() => {
                      handleStepChange(3);
                      handleDomainBlur();
                      handleUrlNameBlur();
                    }}
                  >
                    Subscription
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className={activeStep === 1 ? "" : "d-none"}>
                    <div className='mb-3'>
                      <label htmlFor='name' className='col-form-label'>
                        Name:
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='email' className='col-form-label'>
                        Email:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        id='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleEmailBlur}
                      />
                      {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='phone' className='col-form-label'>
                        Phone:
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                        id='phone'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength={10}
                        pattern='[0-9]*'
                        onBlur={handlePhoneBlur}
                      />
                      {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                    </div>
                  </div>
                  <div className={activeStep === 2 ? "" : "d-none"}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='hotelName' className='col-form-label'>
                          Hotel / Brand Name
                        </label>
                        <input type='text' className={`form-control ${errors.hotelName ? "is-invalid" : ""}`} name='hotelName' id='hotelName' value={formData.hotelName} onChange={handleChange} />
                        {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='accountType' className='col-form-label'>
                          Account Type:
                        </label>
                        <select id='accountType' className={`form-select`} value={accountType} onChange={handleAccountType}>
                          <option value='true'>Client</option>
                          <option value='false'>Demo</option>
                        </select>
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='urlName' className='col-form-label'>
                          URL Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.urlName ? "is-invalid" : ""}`}
                          id='urlName'
                          name='urlName'
                          value={formData.urlName}
                          onChange={handleChange}
                          onBlur={handleUrlNameBlur}
                        />
                        {errors.urlName && <div className='invalid-feedback'>{errors.urlName}</div>}
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="domain" className="col-form-label">Domain Name:</label>
                        <input type="text" className={`form-control `} id="domain" value={formData.domain} name='domain' onChange={handleChange} onBlur={handleDomainBlur} />
                      </div> */}

                      <div className='col-md-6'>
                        <label htmlFor='domain' className='col-form-label'>
                          Domain Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.domain ? "is-invalid" : ""}`}
                          id='domain'
                          value={formData.domain}
                          name='domain'
                          onChange={handleChange}
                          onBlur={handleDomainBlur}
                        />
                        {errors.domain && <div className='invalid-feedback'>{errors.domain}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='companyName' className='col-form-label'>
                          Company Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                          id='companyName'
                          name='companyName'
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                        {errors.companyName && <div className='invalid-feedback'>{errors.companyName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='gstNumber' className='col-form-label'>
                          GST Number:
                        </label>
                        <input type='text' className={`form-control`} id='gstNumber' name='gstNumber' value={formData.gstNumber} onChange={handleChange} />
                      </div>

                      <div className='col-md-12 mb-3'>
                        <label htmlFor='address' className='col-form-label'>
                          Address:
                        </label>
                        <input type='text' className={`form-control ${errors.address ? "is-invalid" : ""}`} id='address' name='address' value={formData.address} onChange={handleChange} />
                        {errors.address && <div className='invalid-feedback'>{errors.address}</div>}
                      </div>
                    </div>
                  </div>
                  <div className={activeStep === 3 ? "" : "d-none"}>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionName' className='col-form-label'>
                          Subscription Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.subscriptionName ? "is-invalid" : ""}`}
                          id='subscriptionName'
                          name='subscriptionName'
                          value={formData.subscriptionName}
                          onChange={handleChange}
                        />
                        {errors.address && <div className='invalid-feedback'>{errors.address}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionRoom' className='col-form-label'>
                          Subscription Rooms:
                        </label>
                        <input
                          type='number'
                          className={`form-control ${errors.subscriptionRooms ? "is-invalid" : ""}`}
                          id='subscriptionRoom'
                          name='subscriptionRooms'
                          value={formData.subscriptionRooms}
                          onChange={handleChange}
                        />
                        {errors.subscriptionRooms && <div className='invalid-feedback'>{errors.subscriptionRooms}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='price' className='col-form-label'>
                          Subscription Amount:
                        </label>
                        <input type='number' className={`form-control ${errors.price ? "is-invalid" : ""}`} id='price' value={formData.price} name='price' onChange={handleChange} />
                        {errors.price && <div className='invalid-feedback'>{errors.price}</div>}
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label htmlFor='subscriptionValidity' className='col-form-label'>
                          Subscription Validity:
                        </label>
                        <input
                          type='date'
                          className={`form-control ${errors.subscriptionValidity ? "is-invalid" : ""}`}
                          id='subscriptionValidity'
                          name='subscriptionValidity'
                          value={formData.subscriptionValidity}
                          onChange={handleChange}
                        />
                        {errors.subscriptionValidity && <div className='invalid-feedback'>{errors.subscriptionValidity}</div>}
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    {activeStep > 1 && (
                      <button type='button' className='btn btn-secondary' onClick={handlePrevStep} disabled={isSubmittingError}>
                        Previous
                      </button>
                    )}
                    {activeStep < 3 && (
                      <button type='button' className='btn btn-primary' onClick={handleNextStep} disabled={isSubmittingError}>
                        Next
                      </button>
                    )}
                    {activeStep === 3 && (
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting || isSubmittingError}>
                        {isSubmitting ? "Loading..." : "Submit"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
