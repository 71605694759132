import { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

// import Modal from "../ui/modals/Modal";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";

export default function InventoryModal({
  isModalOpen,
  setIsModalOpen,
  modalName,
  defaultBasePrice,
  saveFunc,
  view = "add",
  editItem = null,
  selectedStartDate,
  selectedEndDate,
  selectedProperty,
  selectedApartment,
}) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [formData, setFormData] = useState({
    type: "everyday",
    date_from: selectedStartDate,
    date_to: selectedEndDate,
    property: selectedProperty,
    room: selectedApartment,
    base_price: editItem?.base_price || defaultBasePrice,
    price_for_variants: [],
  });

  const [pricingTypeVariants, setPricingTypeVariants] = useState([]);

  const [calculatedPrice, setCalculatedPrice] = useState([]);
  const [autoCalculatedPrice, setAutoCalculatedPrice] = useState([]);

  const handleChangeInput = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSetValue = (name, value) => setFormData((prev) => ({ ...prev, [name]: value }));

  const validateDate = (name, date) => {
    if (name === "date_from") {
      if (formData.date_to && date > formData.date_to) toast.error("Please select a valid date");
      else handleSetValue(name, date);
    } else {
      if (formData.date_from && formData.date_from > date) toast.error("Please select a valid date");
      else handleSetValue(name, date);
    }
  };

  const handleChangePrice = (e) => {
    const id = e.target.name;
    setFormData((prev) => ({
      ...prev,
      price_for_variants:
        prev.price_for_variants.filter((item) => item?.pricing_type_variant_id === id).length > 0
          ? prev.price_for_variants?.map((item) => (item?.pricing_type_variant_id === id ? { ...item, rate: e.target.value } : item))
          : [...prev.price_for_variants, { pricing_type_variant_id: id, rate: e.target.value }],
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const item = {
      ...editItem,
      ...formData,
    };
    saveFunc(item);
    setIsModalOpen(false);
  };

  const putValues = () => {
    // setFormData(editItem);
    setFormData((prev) => ({ ...prev, ...editItem, base_price: editItem?.base_price, date_to: editItem?.date_to, date_from: editItem?.date_from }));
  };

  useEffect(() => {
    if (pricingTypeVariants) {
      if (editItem) {
        putValues();
      }
    }
  }, [editItem, pricingTypeVariants]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response2 = await fetch(url + clientpoints.pricing_type_variant_view, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ urlName: hotelId, filters: {} }),
        });

        const variantData = await response2.json();

        setPricingTypeVariants(variantData.Data);

        if (editItem?.price_for_variants == undefined || editItem?.price_for_variants == null || editItem?.price_for_variants.length <= 0) {
          setCalculatedPrice(
            variantData?.Data?.map((item) => ({
              pricing_type_variant_id: item._id,
              rate: 0,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchFormulaBasedPrices = async () => {
    try {
      const response = await fetch(url + clientpoints.room_inventory_price_view_by_formula, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({
          urlName: hotelId,
          filters: { room_id: formData.room, base_price: parseFloat(formData.base_price) },
        }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch inventory data");
      }
      const data = await response.json();

      const autoCalculatedRates = data.Data;

      setAutoCalculatedPrice(data.Data);

      if (editItem?.base_price !== formData.base_price) {
        setFormData((prev) => ({
          ...prev,
          price_for_variants: data.Data,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (formData.base_price > 0 && pricingTypeVariants) {
      fetchFormulaBasedPrices();
    }
  }, [formData.base_price, formData.room, editItem?.base_price, pricingTypeVariants]);

  useEffect(() => {
    if (calculatedPrice.length > 0 && !editItem && pricingTypeVariants) {
      setFormData((prev) => ({ ...prev, price_for_variants: calculatedPrice }));
    }
  }, [calculatedPrice, pricingTypeVariants, editItem]);

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content' style={{ maxHeight: "90vh", overflowY: "auto" }}>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={() => setIsModalOpen(false)}></button>
              </div>

              <form onSubmit={handleSave} className='d-flex flex-column gap-3 p-4 text-gray-600'>
                <DateInput label='From date' name='date_from' value={formData.date_from} onChange={validateDate} />
                <DateInput label='To date' name='date_to' value={formData.date_to} onChange={validateDate} />
                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                  <label className='d-flex gap-1 w-fit'>
                    <input className='form-check-input m-0' type='radio' name='type' value='everyday' checked={formData.type === "everyday"} onChange={handleChangeInput} />
                    All Days
                  </label>
                  <label className='d-flex gap-1 w-fit'>
                    <input className='form-check-input m-0' type='radio' name='type' value='weekdays' checked={formData.type === "weekdays"} onChange={handleChangeInput} />
                    Weekdays (Mon-Fri)
                  </label>
                  <label className='d-flex gap-1 w-fit'>
                    <input className='form-check-input m-0' type='radio' name='type' value='weekend' checked={formData.type === "weekend"} onChange={handleChangeInput} />
                    Weekends (Sat - Sun)
                  </label>
                </div>
                {/* <NormalInput type='number' label='Number of Available rooms' name='available_rooms' value={formData.available_rooms} onChange={handleChangeInput} /> */}
                <NormalInput type='number' label='Price' name='base_price' value={formData.base_price} onChange={handleChangeInput} />
                {/* {editItem && <p>{`${rooms.booked} of ${formData.available_rooms} sold out`}</p>} */}
                <div className='d-flex flex-column gap-2'>
                  <div className='d-flex align-items-center justify-content-between text-gray-400 font-weight-bold'>
                    Pricing Type Variants
                    <button
                      type='button'
                      // onClick={() => fetchFormulaBasedPrices()}
                      onClick={() => setFormData((prev) => ({ ...prev, price_for_variants: autoCalculatedPrice }))}
                      disabled={formData.price_for_variants === autoCalculatedPrice || formData.base_price === 0}
                      className='btn btn-primary btn-sm ms-auto'
                    >
                      Reset to auto-calculated
                    </button>
                  </div>

                  {formData?.price_for_variants?.map((item, i) => (
                    <div className='d-flex flex-column gap-2' key={i}>
                      <label className='d-flex align-items-center justify-content-between' style={{ fontSize: "14px" }}>
                        {(() => {
                          const variant = pricingTypeVariants?.find((am) => am._id === item?.pricing_type_variant_id);
                          return `${variant?.pricing_type_id.name} ${variant?.name}`;
                        })()}
                        <input type='number' name={item?.pricing_type_variant_id} value={item?.rate} onChange={handleChangePrice} className='form-control w-auto' />
                      </label>
                    </div>
                  ))}

                  {/* {formData?.price_for_variants?.map((item, i) => (
                    <div className='d-flex flex-column gap-2' key={i}>
                      <label className='d-flex align-items-center justify-content-between' style={{ fontSize: "14px" }}>
                        {(() => {
                          const variant = pricingTypeVariants?.find((am) => am._id === item?.pricing_type_variant_id);
                          return `${variant?.pricing_type_id.name} ${variant?.name}`;
                        })()}
                        <input type='number' name={item?.pricing_type_variant_id} value={item?.rate} onChange={handleChangePrice} className='form-control w-auto' />
                      </label>
                    </div>
                  ))} */}
                </div>
                <div className='d-flex justify-content-center'>
                  <button type='submit' className='btn btn-primary mt-2 w-100'>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export const NormalInput = ({ label, type = "text", name = "", disabled = false, readOnly = false, value = "", onChange, min = "", max = "", inputStyles = {}, placeholder = "" }) => {
  return (
    <label className='d-flex flex-column gap-1 w-100 text-gray-400 font-weight-bold'>
      {label}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        className='form-control'
        style={inputStyles}
      />
    </label>
  );
};
export const DateInput = ({ label, name = "", disabled = false, value = "", onChange, format = "dd/MM/yyyy" }) => {
  return (
    <label className='d-flex flex-column gap-1 w-100 text-gray-400 font-weight-bold'>
      {label}
      <DatePicker selected={value} onChange={(date) => onChange(name, date)} disabled={disabled} dateFormat={format} className='form-control' />
    </label>
  );
};
