import AllBookings from "../../components/booking/all_booking/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <AllBookings name='All Bookings' />
    </>
  );
}
