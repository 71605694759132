import React, { useState, useEffect, useRef } from "react";
import { url, clientpoints, clientHeader } from "../../../../../../lib/lib.js";
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ setMemberName, searchValueProps, suggestionsProps, showDropdownProps, form = true, locationName = "" }) => {
  const { hotelId } = useParams();
  const { searchValueMember, setSearchValueMember } = searchValueProps;
  const { suggestionsMember, setSuggestionsMember } = suggestionsProps;
  const { showDropdownMember, setShowDropdownMember } = showDropdownProps;

  const [onClickOption, setOnClickOption] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  let [countIndex, setCountIndex] = useState(0);
  const loadOptions = async () => {
    try {
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: searchValueMember, filters: { ...(locationName && { location_id: locationName }) } }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsMember(data);
      setOnClickOption(true);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: "", filters: { ...(locationName && { location_id: locationName }) }, page: page, size: perPage, sorting: { createdAt: "1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const newData = responseData.Data;
      setOnClickOption(true);
      setSuggestionsMember((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: clientHeader,
        // body: JSON.stringify({ search: '', filters: { hotel_id: hotelId, }, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
        body: JSON.stringify({ search: "", filters: { hotel_id: hotelId, ...(locationName && { location_id: locationName }) }, page: countIndex + 1, sorting: { createdAt: "1" } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsMember(data);
      setOnClickOption(true);
      setTotalPages(responseData.totalCount);
      setSearchValueMember("");
    } catch (error) {
      console.error("Error fetching options:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      const { floor_name, _id } = selectedOption;
      setSearchValueMember(floor_name);
      setMemberName(_id);
      setShowDropdownMember(floor_name);
      setSuggestionsMember([]);
    } else if (selectedOption == "") {
      setSearchValueMember("All Floor");
      setShowDropdownMember("All Floor");
      setMemberName("");
      setSuggestionsMember([]);
    } else {
      setSearchValueMember("");
      setShowDropdownMember("");
      setMemberName("");
      setSuggestionsMember([]);
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestionsMember([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValueMember(showDropdownMember);
        setOnClickOption(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdownMember]);

  return (
    <div className='dropdown' ref={dropdownRef}>
      <i className='ri-arrow-down-s-line ab1' onClick={loadOptionsOnClick}></i>
      <input
        autoComplete='off'
        ref={inputRef}
        placeholder={form ? "Search for Floor..." : "Select Floor"}
        onChange={(e) => setSearchValueMember(e.target.value)}
        value={searchValueMember}
        onKeyUp={() => loadOptions(searchValueMember)}
        onClick={loadOptionsOnClick}
        id='hotelName'
        className='search-input form-control customSelect w-100'
      />
      {isLoading ? (
        <div className='spinner-border text-primary my_spin' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        onClickOption && (
          <ul id='dropdownMenu' onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className='dropdown-menu mydrop_hgt'>
            {form ? (
              <li onClick={() => handleOptionClick("")}>
                All Floor<span className='hidden' data-client-id={""}></span>
              </li>
            ) : (
              ""
            )}
            {suggestionsMember.length > 0 ? (
              suggestionsMember.map((floor) => (
                <li key={floor._id} onClick={() => handleOptionClick(floor)}>
                  {floor.floor_name}
                  {/* {floor.floor} {floor.email && floor.email ? `( ${floor.email} ) ` : ''} */}
                  <span className='hidden' data-floor-id={floor._id} data-client-id={floor.client_id ? floor.client_id._id : ""}></span>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        )
      )}
    </div>
  );
};

export default Component;
