import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { AmenityMultiCheck } from "../amenity_multi_check/Component.js";
import Select from "react-select";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, data = "", view }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [amenities, setAmenities] = useState([]);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectValues, setSelectValues] = useState({
    property_id: "",
    room_type: "",
  });

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  let roomId = data;

  useEffect(() => {
    const property = properties?.find((item) => item._id === formData.property_id) || "";
    setSelectValues((prev) => ({
      ...prev,
      property_id: { label: property.title, value: property._id },
    }));
  }, [formData.property_id, properties]);

  useEffect(() => {
    const selectedType = roomTypes?.find((item) => item._id === formData.room_type);
    setSelectValues((prev) => ({
      ...prev,
      room_type: selectedType ? { label: selectedType.name, value: selectedType._id } : "",
    }));
  }, [formData.room_type, roomTypes]);

  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.apartment_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const propertiesResponse = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const roomTypesResponse = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const pricingTypesResponse = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !propertiesResponse.ok || !roomTypesResponse.ok || !pricingTypesResponse) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      const propertiesData = await propertiesResponse.json();
      const roomTypesData = await roomTypesResponse.json();
      const pricingTypesData = await pricingTypesResponse.json();

      setAmenities(amenitiesData.Data);
      setProperties(propertiesData.Data);
      setRoomTypes(roomTypesData.Data);
      setPricingTypes(pricingTypesData.Data);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for edit modal ---------------------------------------------------------------------------------
  useEffect(() => {
    if (isModalOpen && roomId && (view === "edit" || view === "view")) {
      // setLoading(true);
      fetchRoomData(roomId).then(() => {
        setLoading(false);
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        amenities: pricingTypes?.map((item) => ({
          category: item._id,
          amenities: [],
        })),
      }));
    }
  }, [isModalOpen, roomId, pricingTypes, view]);

  const fetchRoomData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.room_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        console.log("room dataaaa", response_data);

        const mappedAmenities = pricingTypes.map((type) => {
          const matchingAmenities = response_data?.amenities?.find((apartmentAmenity) => apartmentAmenity.category === type._id);

          return {
            category: type._id,
            amenities: matchingAmenities ? matchingAmenities.amenities : [],
          };
        });

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
          property_id: response_data.property_id._id,
          amenities: mappedAmenities,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // ----------------------------------------------------------------

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectAmenities = (category, value) => {
    console.log(category, value);

    setFormData((prev) => ({
      ...prev,
      amenities: prev.amenities.map((item) => (item.category === category ? { ...item, amenities: value } : item)),
    }));
  };

  const handleSetValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.property_id.trim()) {
      errors.property_id = "Property is required";
      isValid = false;
    }

    if (!formData.room_type.trim()) {
      errors.room_type = "Room Type is required";
      isValid = false;
    }

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (formData.base_price == 0) {
      errors.base_price = "Base Price cannot be zero";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let apiEndpoint = "";
    let filters = {};

    let requestBody = {};

    if (view === "add") {
      apiEndpoint = clientpoints.room_add;
      requestBody = { urlName: hotelId, ...formData };
    } else if (view === "edit") {
      apiEndpoint = clientpoints.room_edit;
      filters = { _id: roomId };
      requestBody = {
        urlName: hotelId,
        filters: filters,
        data: { ...formData },
      };
    }
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + apiEndpoint, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
          setFormData(initialFormData);
        } else {
          toast.error(responseData.Message);
        }

        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details correctly!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" && `View ${modalName}`}
                  {mode === "edit" && `Edit ${modalName}`}
                  {mode === "add" && `Add ${modalName}`}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ maxHeight: "70vh", overflowY: "scroll", padding: "0 15px" }}>
                    <div className=''>
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className='row'>
                        {/* PROPERTY TYPE-------------------------------- */}
                        <div className='col-md-12'>
                          <label htmlFor='property_id' className='col-form-label'>
                            Property
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={properties?.map((property) => ({ label: `${property.title}`, value: property._id }))}
                            value={selectValues.property_id}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }))
                            }
                            placeholder='Select Property...'
                          />
                          {errors.property_id && <div className='invalid-feedback'>{errors.property_id}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='room_type' className='col-form-label'>
                            Room Type
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={roomTypes?.map((type) => ({ label: type.name, value: type._id }))}
                            value={selectValues.room_type}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                room_type: val.value,
                              }))
                            }
                            placeholder='Select Room Type...'
                          />
                          {errors.room_type && <div className='invalid-feedback'>{errors.room_type}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='name' className='col-form-label'>
                            Name
                          </label>
                          <input disabled={mode === "view"} type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} name='name' id='name' value={formData.name} onChange={handleChange} />
                          {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='description' className='col-form-label'>
                            Description
                          </label>
                          <textarea disabled={mode === "view"} rows={4} type='text' className={`form-control ${errors.description ? "is-invalid" : ""}`} name='description' id='description' value={formData.description} onChange={handleChange} />
                          {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                        </div>
                        <div className='col-md-12'>
                          <label htmlFor='rating' className='col-form-label'>
                            Extra Charges Type
                          </label>
                          <select disabled={mode === "view"} id='rating' className={`form-select`} value={formData.rating} onChange={(e) => setFormData((prev) => ({ ...prev, rating: e.target.value }))}>
                            <option value='Fixed'>Fixed</option>
                            <option value='Per day'>Per day</option>
                          </select>
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='base_price' className='col-form-label'>
                            Base Price
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control ${errors.base_price ? "is-invalid" : ""}`} id='base_price' name='base_price' value={formData.base_price} onChange={handleChange} />
                          {errors.base_price && <div className='invalid-feedback'>{errors.base_price}</div>}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='cleaning_fee' className='col-form-label'>
                            Cleaning Fee
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='cleaning_fee' name='cleaning_fee' value={formData.cleaning_fee} onChange={handleChange} />
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='utility_charges' className='col-form-label'>
                            Utility Charges
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='utility_charges' name='utility_charges' value={formData.utility_charges} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='electric_charges' className='col-form-label'>
                            Electric Charges
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='electric_charges' name='electric_charges' value={formData.electric_charges} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='area_measurement' className='col-form-label'>
                            Area measurement
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='area_measurement' name='area_measurement' value={formData.area_measurement} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_apartments' className='col-form-label'>
                            Number of apartments
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_apartments' name='num_of_apartments' value={formData.num_of_apartments} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_bedrooms' className='col-form-label'>
                            Number of Bedrooms
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_bedrooms' name='num_of_bedrooms' value={formData.num_of_bedrooms} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_beds' className='col-form-label'>
                            Number of Beds
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_beds' name='num_of_beds' value={formData.num_of_beds} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_bathrooms' className='col-form-label'>
                            Number of Bathrooms
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_bathrooms' name='num_of_bathrooms' value={formData.num_of_bathrooms} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_livingRooms' className='col-form-label'>
                            Number of Living Rooms
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_livingRooms' name='num_of_livingRooms' value={formData.num_of_livingRooms} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_adults' className='col-form-label'>
                            Number of Adults
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_adults' name='num_of_adults' value={formData.num_of_adults} onChange={handleChange} />
                        </div>{" "}
                        <div className='col-md-6'>
                          <label htmlFor='num_of_kids' className='col-form-label'>
                            Number of Kids
                          </label>
                          <input disabled={mode === "view"} type='number' className={`form-control `} id='num_of_kids' name='num_of_kids' value={formData.num_of_kids} onChange={handleChange} />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          Active
                          <label>
                            <input disabled={mode === "view"} type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                          </label>
                        </div>{" "}
                        <div className='col-md-12 mb-2'>
                          <label htmlFor='notes' className='col-form-label'>
                            Notes
                          </label>
                          <textarea disabled={mode === "view"} rows={4} type='text' className={`form-control `} name='notes' id='notes' value={formData.notes} onChange={handleChange} />
                        </div>
                        <div className='priceAmenitiesContainer'>
                          {formData?.amenities?.map((item, i) => (
                            <div key={i} className='md:col-span-4 col-span-12'>
                              <span className='font-[600] text-gray-400'>{pricingTypes.find((am) => am._id === item.category)?.name} Amenities</span>
                              <AmenityMultiCheck disabled={mode === "view"} options={amenities} selected={item.amenities} category={item.category} onCheck={handleSelectAmenities} />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className='modal-footer'>
                        {view !== "view" && (
                          <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                            {isSubmitting ? "Loading..." : "Submit"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  property_id: "",
  room_type: "",
  name: "",
  description: "",
  extra_charges_type: "Fixed",
  base_price: "",
  cleaning_fee: "",
  utility_charges: "",
  electric_charges: "",
  area_measurement: "",
  isActive: true,
  num_of_apartments: "",
  num_of_bedrooms: "",
  num_of_beds: "",
  num_of_bathrooms: "",
  num_of_livingRooms: "",
  num_of_adults: "",
  num_of_kids: "",
  notes: "",
  amenities: [],
  images: [],
};
