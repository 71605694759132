import React, { useMemo, useState, useEffect } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, adminpoints, admin_prefix, adminHeader } from "../../../../../lib/lib.js";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import EditModalComponent from "../../../components/client_ui/edit_modal/Component.js";
import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import { Link, useNavigate } from "react-router-dom";
import "./component.css";

const Component = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [sorting, setSorting] = useState({
    column: null,
    direction: "desc",
  });

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      const requestBody = {
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();

      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);

      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = { createdAt: "-1" };
      setSearchTerm("");
      handleSort("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }

      setPagination((prevPagination) => ({
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
      }));
    } catch (error) {
      setIsError(true);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: currentPageIndex,
    }));
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    // const currentPageIndex = pagination.pageIndex;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
    // handleHardRefresh();
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if (deleteConfirm == type) {
      try {
        const response = await fetch(url + adminpoints.client_delete, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: deleteItemId } }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
        handleRefresh();
      } catch (error) {
        toast.success(error);
        navigate(`${admin_prefix}login`);
      }
    } else {
      toast.error("Not Matched");
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className='new2'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <h3>All Clients</h3>
          </div>

          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='client_table'>
        <div className='table-responsive mt-2'>
          <div className='hotel_filter m-1'>
            <div className='d-flex'>
              <button onClick={handleRefresh} className='desk-refresh'>
                {isLoading ? (
                  <>
                    <span className='spinner-border spinner-border-sm abs' role='status' aria-hidden='true'></span> <i className='ri-refresh-line hidd'></i>
                  </>
                ) : (
                  <i className='ri-refresh-line'></i>
                )}
                &#160;
                {/* {isLoading ? (<span className="spinner-border spinner-border-sm abs" role="status" aria-hidden="true"></span>) : (<i className="ri-refresh-line"></i>)}&#160; */}
                Refresh
              </button>
              &#160;&nbsp;&nbsp;
              <button className='desk-refresh' onClick={handleHardRefresh}>
                <i className='ri-gradienter-line'></i> Reset
              </button>
            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-between align-items-center asp'>
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={handleSearchSubmit}
                  className='search-input form-control search-inp'
                />
              </div>
            </div>
          </div>
          <div className='scroll_table'>
            <Table hover className='client_table_data'>
              <thead className='brd'>
                <tr className='botm-bordr'>
                  <th>S. No.</th>
                  <th onClick={() => handleSort("name")}>
                    Name <FaFilter />
                    &nbsp; {sorting.column === "name" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                  </th>
                  <th>Email </th>
                  <th>Phone </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className='scrollbody'>
                {isLoading ? (
                  <tr>
                    <td colspan={5}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='spinner-border text-primary ' role='status'>
                          {/* <span className='sr-only'>Loading...</span> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{adjustedStartIndex + index}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>
                        <div className='mybots'>
                          <Link to={admin_prefix + "hotel?id=" + item._id} className='underline_none'>
                            <button variant='primary edit-btn' className='edit-btn1'>
                              <i class='ri-space-ship-line'></i>
                            </button>
                          </Link>
                          <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleEditClick(item)}>
                            <i className='ri-pencil-line'></i>{" "}
                          </button>
                          <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDelete(item._id, item.name)}>
                            <i className='ri-delete-bin-line'></i>{" "}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='middledata'>
                    <td colSpan={5}>
                      <div className='nodatafound1'>No data found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className='pagination-container d-flex justify-content-between align-items-center m-1 page-flx1'>
            <div className='mknk'>
              <Form.Label>Rows per page:</Form.Label>
              <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='form-select form-control dropon'>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
              </select>
            </div>

            <div className=' flex-pagination'>
              <div>{renderPageRange()}</div>
              <div className='mypage1'>
                {pagination.pageIndex !== 1 && data.length !== 0 && (
                  <button
                    disabled={pagination.pageIndex === 1}
                    onClick={() =>
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        pageIndex: prevPagination.pageIndex - 1,
                      }))
                    }
                    class='button left-clickbtn'
                    id='button1'
                  >
                    <i className='ri-arrow-left-s-line'></i>
                  </button>
                )}

                <div class='button active' id='button1'>
                  {pagination.pageIndex}
                </div>

                {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                  <button
                    disabled={pagination.pageIndex === pagination.totalPages}
                    onClick={() =>
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        pageIndex: prevPagination.pageIndex + 1,
                      }))
                    }
                    class='button left-clickbtn'
                    id='button4'
                  >
                    <i className='ri-arrow-right-s-line'></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add New Client"} onRefresh={handleRefresh} />

      <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} onRefresh={handleRefresh} />
    </>
  );
};

export default Component;
