import React, { useEffect, useState, useContext } from "react";
import Sidenav from "../sidenav/Component";
import "../../../../client/components/HotelCard/Component.css";
import Top from "../top/Component";

import { useParams, useNavigate } from "react-router-dom";
import { validateHotelIdForTeam } from "../../../../../utils/ValidateHotelId";
import { url, clientpoints, client_prefix, whoAmIHeaders, getDecryptedDataFromLocalStorage } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";

function Component({ children }) {
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  const { setTypeTeamRoleObject } = useContext(AuthUserContext);

  let pageHeaders;
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const { hotelId } = useParams();

  const [dataRole, setDataRole] = useState("");

  const fetchData = async () => {
    try {
      const requestBody = { urlName: hotelId };

      const response = await fetch(url + clientpoints.hotel_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      if (data.Status) {
        if (whoAmI == "team" || getLocalStorageData == "team") {
          const responseTeam = await fetch(url + clientpoints.team_view, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(requestBody),
          });

          const dataTeam = await responseTeam.json();
          setName(dataTeam.Data[0].name);
          setHotelName(dataTeam.Data[0].hotel_id.hotel_name);

          let team_role_id = dataTeam.Data[0].role_id;
          const responseRole = await fetch(url + clientpoints.role_view, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({ urlName: hotelId, filters: { _id: team_role_id } }),
          });

          if (!responseRole.ok) {
            navigate(`${client_prefix}`);
          }

          let dataResponseRole = await responseRole.json();
          let dataRole = dataResponseRole.Data[0];
          setDataRole(dataRole);
          setTypeTeamRoleObject(dataRole);
        } else if (whoAmI == "client" || getLocalStorageData == "client") {
          setName(data.Data[0].client_id.name);
          setHotelName(data.Data[0].hotel_name);
        } else {
          setName("StayStation");
          setHotelName("StayStation");
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndValidateHotel = async () => {
      const response = await validateHotelIdForTeam(hotelId);
      if (response) {
        if (pageHeaders) {
          fetchData();
        } else {
          const getLocalStorageData = getDecryptedDataFromLocalStorage("type");
          setWhoAmI(getLocalStorageData);
        }
      } else {
        if (whoAmI == "client") {
          navigate("/hotels");
        } else {
          navigate("/");
        }
      }
    };

    fetchDataAndValidateHotel();
  }, [hotelId]);

  return (
    <div className='container-fluid side-padd dashboardfont'>
      <div className='row main-parent'>
        <div className='col_parent_class col-xl-2 col-lg-3 first_child_width'>
          <Sidenav roleObject={dataRole} role={whoAmI} />
        </div>
        <div className='col_parent_class col-xl-10 col-lg-9 second_child_width'>
          <Top name={name} hotelName={hotelName} />
          {/* <br /> */}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Component;
