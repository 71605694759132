import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh }) {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [price, setPrice] = useState("");
  const [subscriptionRoom, setSubscriptionRoom] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [errors, setErrors] = useState({});
  const [clientList, setClientList] = useState([]);
  const [current_id, setCurrent_id] = useState("");
  const [loading, setLoading] = useState(false);

  let clientId = data;

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handleHotelNameChange = (event) => {
    setHotelName(event.target.value);
  };

  const handleSubscriptionRoomChange = (event) => {
    setSubscriptionRoom(event.target.value);
  };

  const handleSubscriptionValidityChange = (event) => {
    setSubscriptionValidity(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!hotelName.trim()) {
      errors.name = "Hotel Name is required";
    }
    if (!clientName.trim()) {
      errors.email = "Client is required";
    }
    if (!price.trim()) {
      errors.phone = "Phone is required";
    }
    if (!subscriptionRoom.trim()) {
      errors.phone = "Room is required";
    }
    if (!subscriptionValidity.trim()) {
      errors.phone = "Validity is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (isModalOpen && clientId) {
      fetchData(clientId);
      fetchClientData();
    }
  }, [isModalOpen, clientId]);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setClientList(data.Data);
    } catch (error) {
      toast.error(error);
      navigate(`${admin_prefix}login`);
    }
  };

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setClientName(response_data.client_id._id);
        setCurrent_id(response_data._id);
        setHotelName(response_data.hotel_name || "");
        setPrice(response_data.subscription_amount || "");
        setSubscriptionRoom(response_data.subscription_rooms || "");
        setSubscriptionValidity(response_data.subscription_validity || "");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
      navigate(`${admin_prefix}login`);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.hotel_edit, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { _id: current_id },
            data: {
              client_id: clientName,
              hotel_name: hotelName,
              subscription_amount: price,
              subscription_rooms: subscriptionRoom,
              subscription_validity: subscriptionValidity,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          toast.success(responseData.Message);
          setClientName("");
          setHotelName("");
          setPrice("");
          setSubscriptionRoom("");
          setSubscriptionValidity("");
          setIsModalOpen(false);
          onRefresh();
        } else {
          toast.error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Edit Hotel
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='clientName' className='col-form-label'>
                        Client Name:
                      </label>
                      <select id='clientName' className={`form-select ${errors.clientName ? "is-invalid" : ""}`} value={clientName} onChange={handleClientNameChange}>
                        <option value=''>Select Client</option>
                        {clientList.map((client) => (
                          <option key={client.id} value={client._id}>
                            {client.name} ({client.phone})
                          </option>
                        ))}
                      </select>
                      {errors.clientName && <div className='invalid-feedback'>{errors.clientName}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='hotelName' className='col-form-label'>
                        Hotel Name:
                      </label>
                      <input type='text' className={`form-control ${errors.hotelName ? "is-invalid" : ""}`} id='hotelName' value={hotelName} onChange={handleHotelNameChange} />
                      {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='subscriptionRoom' className='col-form-label'>
                        Subscription Rooms:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.subscriptionRoom ? "is-invalid" : ""}`}
                        id='subscriptionRoom'
                        value={subscriptionRoom}
                        onChange={handleSubscriptionRoomChange}
                      />
                      {errors.subscriptionRoom && <div className='invalid-feedback'>{errors.subscriptionRoom}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='subscriptionValidity' className='col-form-label'>
                        Subscription Validity:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.subscriptionValidity ? "is-invalid" : ""}`}
                        id='subscriptionValidity'
                        value={subscriptionValidity}
                        onChange={handleSubscriptionValidityChange}
                      />
                      {errors.subscriptionValidity && <div className='invalid-feedback'>{errors.subscriptionValidity}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='price' className='col-form-label'>
                        Price:
                      </label>
                      <input type='number' className={`form-control ${errors.price ? "is-invalid" : ""}`} id='price' value={price} onChange={handlePriceChange} />
                      {errors.price && <div className='invalid-feedback'>{errors.price}</div>}
                    </div>
                  </div>
                )}
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                    Close
                  </button>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
