import { Link } from "react-router-dom";
// import "./component.css";

const Component = () => {
  return (
    <>
    <div className="my-top">
    <div className="container">
    {/* <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center> */}
    <div className="row feature-top02">
      <div className="col-lg-6">
        <center><img src="img/FrontOffice.png" className="img-fluid feature-img2" alt="Front Office" /></center>
      </div>
      <div className="col-lg-6">
        <div className="feature-padd2">
          <p className="stay-txt2">Front Office & Offers Management</p>
          {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
          <div className="desk-top-2">
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Streamlined check-in and check-out processes for guest convenience.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">  Customizable room status updates for accurate housekeeping coordination.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Guest communication tools for timely notifications and requests.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Customizable promotions and discounts to attract guests.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Efficiently manage coupon codes to ensure secure offer redemption.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </>

);
};

export default Component;
