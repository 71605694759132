import { LineWave, MutatingDots } from "react-loader-spinner";

export const LoadingMutatingDots = ({
  primaryColor = "#208FE1",
  secondaryColor = "#417ACF",
  radius = "12.5",
  className,
  containerStyles = {},
}) => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video width='100%' height='30' autoPlay muted loop controls={false}>
        <source src='/img/loader.mp4' type='video/mp4' />
      </video>
    </div>
  );
};

// export const LoadingMutatingDots = ({
//   primaryColor = "#208FE1",
//   secondaryColor = "#417ACF",
//   radius = "12.5",
//   className,
//   containerStyles = {},
// }) => {
//   return (
//     <div
//       style={{ ...containerStyles }}
//       className={`pt-[30vh] flex justify-center ${className}`}
//     >
//       <MutatingDots
//         height='100'
//         width='100'
//         color={primaryColor}
//         secondaryColor={secondaryColor}
//         radius={radius}
//         ariaLabel='mutating-dots-loading'
//         wrapperStyle={{}}
//         wrapperClass=''
//         visible={true}
//       />
//     </div>
//   );
// };

export const LoadingLineWave = ({
  color = "#EE2E24",
  colors = [],
  className,
}) => {
  return (
    <div className={`py-2 flex justify-center ${className}`}>
      <LineWave
        height='100'
        width='100'
        color={color}
        ariaLabel='line-wave'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        firstLineColor={colors[0]}
        middleLineColor={colors[1]}
        lastLineColor={colors[2]}
      />
    </div>
  );
};
