import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const hideNavbar = () => {
    setIsMenuActive(false);
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const collapseSubMenu = () => {
    const activeSubMenu = document.querySelector('.menu-dropdown.active .submenu');
    if (activeSubMenu) {
      activeSubMenu.removeAttribute('style');
      document.querySelector('.menu-dropdown.active').classList.remove('active');
    }
  };

  const toggleSubMenu = (e) => {
    // Check if the clicked element is meant for toggling a submenu
    if (e.target.hasAttribute('data-toggle') && window.innerWidth <= 992) {
      e.preventDefault();
      const menuDropdown = e.target.parentElement;
      if (menuDropdown.classList.contains('active')) {
        collapseSubMenu();
      } else {
        if (document.querySelector('.menu-dropdown.active')) {
          collapseSubMenu();
        }
        menuDropdown.classList.add('active');
        const subMenu = menuDropdown.querySelector('.submenu');
        subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
      }
    } else {
      // If it's a regular menu item and not a submenu toggle, hide the navbar
      hideNavbar();
    }
  };

  const closeMenuOnClickOutside = (e) => {
    if (!e.target.closest('.header') && isMenuActive) {
      setIsMenuActive(false);
    }
  };

  const resizeWindow = () => {
    if (window.innerWidth > 992) {
      if (isMenuActive) {
        toggleMenu();
      }
      collapseSubMenu();
    }
  };

  // Event Listeners
  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    document.body.addEventListener('click', closeMenuOnClickOutside);
    return () => {
      window.removeEventListener('resize', resizeWindow);
      document.body.removeEventListener('click', closeMenuOnClickOutside);
    };
  }, [isMenuActive]);
  

  return (
    <header className="header" id="header">
      <section className="wrapper">
        <div className="brand" >
          <img className="logo" src="/img/staystation.png" alt="Logo" />
        </div>
        <div className="burger" id="burger" onClick={toggleMenu} style={{ display: isMenuActive ? 'none' : 'block' }}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>
        {isMenuActive && (
          <i className="ri-close-line close-icon" onClick={toggleMenu}></i>
        )}
        {isMenuActive && <span className="overlay" onClick={toggleMenu}></span>}
        <nav className={`navbar ${isMenuActive ? 'active' : ''}`} id="navbar">
          <ul className="menu" id="menu" onClick={toggleSubMenu}>
            {/* <li className="menu-item acc-item"><Link to="/" className="menu-link">Home</Link></li> */}
            <li className="menu-item acc-item"><a href="#features" className="menu-link">Features</a></li>
            <li className="menu-item acc-item"><a href="#pricing" className="menu-link">Pricing</a></li>
            <li className="menu-item acc-item"><a href="#contact" className="menu-link">Get Free Trial</a></li>
            <li className="menu-item acc-item">
              <Link to="/login" className="menu-link contact-bt" onClick={hideNavbar}>Login</Link>
            </li>
          </ul>
        </nav>
      </section>
    </header>
  );
};

export default Component;
