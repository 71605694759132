import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="my-top mt-5" id="features">
    <div className="container">
    <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center>
    <div className="row feature-top02">
      <div className="col-lg-6">
        <center><img src="img/booking.png" className="img-fluid feature-img2" alt="Booking" /></center>
      </div>
      <div className="col-lg-6">
        <div className="feature-padd2">
          <p className="stay-txt2">Booking Engine & Reservations Management</p>
          {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
          <div className="desk-top-2">
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Streamline reservation processes with real-time availability updates.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Manage Booking cancellations and modifications with ease.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Automated email confirmations enhance guest communication.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Dynamic pricing tools to maximize revenue during peak times.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Automated billing and invoicing for streamlined financial operations.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </>

);
};

export default Component;
