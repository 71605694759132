import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { url, adminpoints, admin_prefix, adminHeader } from "../../../../../lib/lib.js";

const Component = ({ setClientName, searchValueProps, suggestionsProps, showDropdownProps, form = true, value = "", setPagination = "" }) => {
  const navigate = useNavigate();
  const { searchValue, setSearchValue } = searchValueProps;
  const { suggestions, setSuggestions } = suggestionsProps;
  const { showDropdown, setShowDropdown } = showDropdownProps;
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  let [countIndex, setCountIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loadOptions = async () => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ search: searchValue }),
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ search: "", page: page, size: perPage, sorting: { createdAt: "-1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestions((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        body: JSON.stringify({ search: "", page: countIndex + 1, size: 10, sorting: { createdAt: "-1" } }), // Increment countIndex by 1
        credentials: "include",
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestions(data);
      setTotalPages(responseData.totalCount);
      setSearchValue("");
    } catch (error) {
      navigate(`${admin_prefix}login`);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    if (setPagination != "") {
      const currentPageIndex = 1;
      setPagination((prevPagination) => ({ ...prevPagination, pageIndex: currentPageIndex }));
    }

    if (selectedOption) {
      const { name, _id } = selectedOption;
      setSearchValue(name);
      setClientName(_id);
      setShowDropdown(name);
      setSuggestions([]);
    } else if (selectedOption == "") {
      setClientName("");
      setSuggestions([]);
      setShowDropdown("All Clients");
      setSearchValue("All Clients");
      const urlParams = new URLSearchParams(window.location.search);
      const idFromUrl = urlParams.delete("id");
      navigate(admin_prefix + "hotel");
    } else {
      setSearchValue("All Clients");
      setShowDropdown("");
      setClientName("");
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValue(showDropdown);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  return (
    <div className='spdrop'>
      <div className='dropdown ab' ref={dropdownRef}>
        <i className='fa fa-angle-down ab1' onClick={loadOptionsOnClick}></i>
        <input
          autoComplete='off'
          ref={inputRef}
          placeholder={form ? "Search Clients..." : "Select Clients"}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          // value={searchValue || "All Clients"}
          onKeyUp={() => loadOptions(searchValue)}
          onClick={loadOptionsOnClick}
          id='hotelName'
          className='search-input form-control customSelect w-100'
        />
        {isLoading ? (
          <div className='spinner-border text-primary my_spin' role='status'>
            {/* <span className='sr-only'>Loading...</span> */}
          </div>
        ) : (
          suggestions.length > 0 && (
            <ul
              id='dropdownMenu spdrop'
              onScroll={handleScroll}
              // style={{ overflowY: 'scroll', height: '300px', background:'white' }}
              className='dropdown-menu mydrop_hgt'
            >
              {form ? (
                <li onClick={() => handleOptionClick("")}>
                  All Clients<span className='hidden' data-client-id={""}></span>
                </li>
              ) : (
                ""
              )}
              {suggestions.length > 0 ? (
                suggestions.map((client) => (
                  <li key={client._id} onClick={() => handleOptionClick(client)}>
                    {client.name}
                    <span className='hidden' data-client-id={client._id}></span>
                  </li>
                ))
              ) : (
                <li>No data found</li>
              )}
            </ul>
          )
        )}
      </div>
    </div>
  );
};

export default Component;
