import Reviews from "../../components/Resources/review/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <Reviews name='Reviews' />
    </>
  );
}
