import { Link } from "react-router-dom";
// import "./component.css";

const Component = () => {
  return (
    <>
    <div className="my-top">
    <div className="container">
    {/* <center><p class="stay-feature">Explore Our Features</p><hr class="stay-line"/></center> */}
    <div className="row feature-top02">
      <div className="col-lg-6">
        <center><img src="img/CustomBranding.png" className="img-fluid feature-img2" alt="Branding" /></center>
      </div>
      <div className="col-lg-6">
        <div className="feature-padd2">
          <p className="stay-txt2">Custom Branding & Integrations</p>
          {/* <p className="stay-descri">Order tracking, invoicing and order reports.</p> */}
          <div className="desk-top-2">
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Customizable website themes to match your brand's aesthetics.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Enhance guest experiences with white-label mobile apps for a seamless journey.</p>
                {/* <p className="feature1-txt2">White-label mobile apps for a seamless guest journey.</p> */}
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Seats &amp; Cabins Mapping</p> */}
                <p className="feature1-txt2">Personalized email templates and Invoices for guest interactions.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2">Seamless integration with leading payment gateways for diverse payment options.</p>
              </div>
            </div>
            <div className="stay-feature1-flx">
              <div className="flx2"><i className="ri-bard-line feature-icn"></i></div>
              <div className="flx3">
                {/* <p className="feature-txt1">Meeting Room Bookings</p> */}
                <p className="feature1-txt2"> Sync availability and rates across multiple OTAs for centralized management.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </>

);
};

export default Component;
