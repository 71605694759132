import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import Select from "react-select";
import { Country, State, City } from "country-state-city";

import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [selectValues, setSelectValues] = useState({
    country: { label: "", value: "" },
    state: {
      label: "",
      value: "",
    },
    city: {
      label: "",
      value: "",
    },
  });

  const initialFormData = {
    country: { name: "", isoCode: "" },
    state: { name: "", isoCode: "" },
    city: "",
    area_name: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const allCountries = Country.getAllCountries();
  const countries = allCountries.map((con) => ({ label: con.name, value: con.isoCode }));

  useEffect(() => {
    const country = countries.find((item) => item.value === formData.country.isoCode) || { label: "", value: "" };
    setSelectValues((prev) => ({
      ...prev,
      country,
    }));

    const statesOfCountry = State.getStatesOfCountry(formData.country.isoCode);
    const statesList = statesOfCountry.map((state) => ({ label: state.name, value: state.isoCode }));

    setStates(statesList);
  }, [formData.country.isoCode]);

  useEffect(() => {
    const state = states.find((item) => item.value === formData.state.isoCode) || { label: "", value: "" };
    setSelectValues((prev) => ({
      ...prev,
      state,
    }));

    const citiesOfState = City.getCitiesOfState(formData.country.isoCode, formData.state.isoCode);

    setCities(citiesOfState.map((city) => ({ label: city.name, value: city.name })));
  }, [formData.country.isoCode, formData.state.isoCode, states]);

  useEffect(() => {
    const city = cities.find((item) => item.value === formData.city) || { label: "", value: "" };

    setSelectValues((prev) => ({
      ...prev,
      city,
    }));
  }, [formData.city, formData.state.isoCode, cities]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let areaId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.country.isoCode.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!formData.state.isoCode.trim()) {
      errors.state = "State is required";
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
      isValid = false;
    }

    if (!formData.area_name.trim()) {
      errors.area_name = "Area is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && areaId) {
      setLoading(true);
      fetchData(areaId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, areaId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.area_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          country: { ...response_data.country },
          state: { ...response_data.state },
          city: response_data.city,
          area_name: response_data.area_name,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.area_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              country: { ...formData.country },
              state: { ...formData.state },
              city: formData.city,
              area_name: formData.area_name,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}{" "}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='country' className='col-form-label'>
                          Country
                        </label>
                        <Select
                          isDisabled={mode === "view"}
                          required
                          options={countries}
                          // options={[
                          //   {
                          //     label: "India",
                          //     value: "IN",
                          //   },
                          // ]}
                          value={selectValues.country}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              country: { name: val.label, isoCode: val.value },
                              state: { name: "", isoCode: "" },
                              city: "",
                            }))
                          }
                          placeholder='Select Country...'
                          className='flex-1 w-full'
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                        {errors.country && <div className='invalid-feedback'>{errors.country}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='state' className='col-form-label'>
                          State
                        </label>
                        <Select
                          required
                          isDisabled={!formData.country.isoCode || mode === "view"}
                          options={states}
                          value={selectValues.state}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              state: { name: val.label, isoCode: val.value },
                              city: "",
                            }))
                          }
                          placeholder='Select State...'
                          className='flex-1 w-full'
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                        {errors.state && <div className='invalid-feedback'>{errors.state}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='city' className='col-form-label'>
                          City
                        </label>
                        <Select
                          required
                          isDisabled={!formData.country.isoCode || !formData.state.isoCode || mode === "view"}
                          options={cities}
                          value={selectValues.city}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              city: val.value,
                            }))
                          }
                          placeholder='Select City...'
                          className='flex-1 w-full'
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                        {errors.city && <div className='invalid-feedback'>{errors.city}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='area_name' className='col-form-label'>
                          Area
                        </label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.area_name ? "is-invalid" : ""}`} id='area_name' name='area_name' value={formData.area_name} onChange={handleChange} />
                        {errors.area_name && <div className='invalid-feedback'>{errors.area_name}</div>}
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button>
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
