import React, { useMemo, useState, useEffect, useContext } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "./component.css";
import { url, clientpoints, getDecryptedDataFromLocalStorage, whoAmIHeaders, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext";
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ header, head }) => {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const headerKeyMap = {
    "Subscription Name": "subscription_name",
    "Subscription Rooms": "subscription_rooms",
    "Subscription Validity": "subscription_validity",
    "Base Amount": "base_amount",
    "Payment Date": "payment_date",
    // Add more mappings as needed
  };
  const headersWithSno = ["Sno", ...header];

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [sorting, setSorting] = useState({
    column: null,
    direction: "desc",
  });

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      // const url = "http://localhost:8080/subscription/view"

      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
        urlName: hotelId,
      };

      const response = await fetch(url + clientpoints.subscription_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      console.log("Response data", responseData);

      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {};
      setSearchTerm("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.client_view, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "admin",
          authorization: "bdjsbdsjs7bHR@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.client_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          search: searchTerm,
          page: 1,
          size: pagination.pageSize,
          urlName: hotelId,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = pagination.pageIndex;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
    handleHardRefresh();
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className='featured-flx'>
        <div className='featured-flx1'>
          <h3>{head}</h3>
        </div>

        {/* <div className="featured-flx1">
          <button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd}><i className="ri-add-line"></i></button>
        </div> */}
      </div>

      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          {/* <Button onClick={handleRefresh} className='ml-4'>
            {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
          </Button>
          &#160; &nbsp;
          <Button onClick={handleHardRefresh}>
            <i className='ri-gradienter-line'></i>
          </Button> */}
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyUp={handleSearchSubmit}
              className='search-input form-control search-inp'
            />
            {/* <button className="btn btn-default" type="submit" variant="primary" onClick={handleSearchSubmit}><i className="ri-search-line"></i></button> */}
          </div>
        </div>
      </div>
      <div className='table-responsive mt-4 tablebord'>
        <Table hover>
          <thead>
            <tr>
              {headersWithSno.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div className='d-flex justify-content-center align-items-center myload'>
                <div className='spinner-border text-primary ' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {header.map((header, index) => (
                    <td key={index}>{item[headerKeyMap[header]]}</td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <div className='pagination-container d-flex justify-content-between align-items-center mt-4 page-flx1'>
        <div className='mknk'>
          <Form.Label>Rows per page:</Form.Label>
          <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='dropon'>
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
          </select>
        </div>

        <div className=' flex-pagination'>
          <div>{renderPageRange()}</div>

          <div className='mypage1'>
            {pagination.pageIndex !== 1 && (
              <button
                disabled={pagination.pageIndex === 1}
                onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex - 1 }))}
                class='button left-clickbtn'
                id='button1'
              >
                <i className='ri-arrow-left-s-line'></i>
              </button>
            )}

            <div class='button active' id='button1'>
              {pagination.pageIndex}
            </div>

            {pagination.pageIndex !== pagination.totalPages && (
              <button
                disabled={pagination.pageIndex === pagination.totalPages}
                onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex + 1 }))}
                class='button left-clickbtn'
                id='button4'
              >
                <i className='ri-arrow-right-s-line'></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
