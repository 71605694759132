import ClientSupport from "../../components/support/client_support/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <ClientSupport />
    </>
  );
}
