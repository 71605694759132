import React from "react";
// import "../../../../../nav/components/location/component.css";
import "./cards.css";

// Card for Cabins, Seats, Meeting Rooms
function Card1({ data, setSelectedItem, setIsEditModal, setIsDeleteModal }) {
    return (
        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass' >
            <div className='cabins-card'>
                <div class='cabin-sideline'></div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='cabin-sheet'>
                            <div className='cabin-number'>
                                <i class='ri-keyboard-box-line keybord-icn'></i>
                            </div>
                            <div className='cabin-text'>
                                <p className='cabin-totalseat'>{data?.name}</p>
                            </div>
                        </div>
                        <div className='botflx-cabin'>
                            <div className='cabin-count'>
                                {data?.number_of_person} Roles
                                {data?.number_of_person == 1 ? "" : "s"}
                            </div>
                            <div className='cabin-editicn'>
                                <i
                                    class='ri-pencil-line pencil-icn'
                                    onClick={() => {
                                        setSelectedItem(data);
                                        setIsEditModal(true);
                                    }}
                                ></i>
                                <i
                                    class='ri-delete-bin-6-line trash-icn'
                                    onClick={() => {
                                        setSelectedItem(data);
                                        setIsDeleteModal(true);
                                    }}
                                ></i>
                            </div>
                            {/* <button className="view-btn-2">Manage Floors</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card1;
