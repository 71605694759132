import React, { useState, useEffect, useContext, useRef } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ disabled = false, propertyName, roomName = "", setRoomName, view = "add", searchValueProps, suggestionsProps, showDropdownProps, form = true }) => {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { searchValueRoom, setSearchValueRoom } = searchValueProps;
  const { suggestionsRoom, setSuggestionsRoom } = suggestionsProps;
  const { showDropdownRoom, setShowDropdownRoom } = showDropdownProps;
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  let [countIndex, setCountIndex] = useState(0);

  const loadOptions = async () => {
    try {
      const response = await fetch(url + clientpoints.room_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, search: searchValueRoom, filters: { property_id: propertyName } }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsRoom(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + clientpoints.room_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, search: "", page: page, size: perPage, sorting: { createdAt: "1" }, filters: { property_id: propertyName } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestionsRoom((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.room_view, {
        method: "POST",
        headers: pageHeaders,
        // body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        body: JSON.stringify({ urlName: hotelId, search: "", filters: { property_id: propertyName }, page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsRoom(data);
      setTotalPages(responseData.totalCount);
      setSearchValueRoom("");
    } catch (error) {
      console.error("Error fetching options:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadMatchedOption = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(url + clientpoints.room_view, {
          method: "POST",
          headers: pageHeaders,
          // body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
          body: JSON.stringify({ urlName: hotelId, search: "", filters: { _id: roomName }, page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch options");
        }
        const responseData = await response.json();
        const data = responseData.Data;

        console.log("roooom", data);

        const { name, _id } = data[0];

        setSearchValueRoom(name);
        setRoomName(_id);
        setShowDropdownRoom(name);
        // setSuggestionsRoom([]);
      } catch (error) {
        console.error("Error fetching options:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (roomName && view === "edit") {
      loadMatchedOption();
    }
  }, [roomName]);

  useEffect(() => {
    setSearchValueRoom("");
    setShowDropdownRoom("");
    setRoomName("");
    setSuggestionsRoom([]);
  }, [propertyName]);

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      const { name, _id } = selectedOption;
      setSearchValueRoom(name);
      setRoomName(_id);
      setShowDropdownRoom(name);
      setSuggestionsRoom([]);
    } else if (selectedOption == "") {
      setSearchValueRoom("All Rooms");
      setShowDropdownRoom("All Rooms");
      setRoomName("");
      setSuggestionsRoom([]);
    } else {
      setSearchValueRoom("");
      setShowDropdownRoom("");
      setRoomName("");
      setSuggestionsRoom([]);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestionsRoom([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValueRoom(showDropdownRoom);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdownRoom]);

  return (
    <div className='dropdown' ref={dropdownRef}>
      <i className='ri-arrow-down-s-line ab1' onClick={loadOptionsOnClick}></i>
      <input
        disabled={disabled}
        autoComplete='off'
        ref={inputRef}
        placeholder={form ? "Search for Room..." : "Select Room"}
        onChange={(e) => setSearchValueRoom(e.target.value)}
        value={searchValueRoom}
        onKeyUp={() => loadOptions(searchValueRoom)}
        onClick={loadOptionsOnClick}
        id='roomName'
        className='search-input form-control customSelect w-100'
      />
      {isLoading ? (
        <div className='spinner-border text-primary my_spin' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        suggestionsRoom?.length > 0 && (
          <ul id='dropdownMenu' onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className='dropdown-menu mydrop_hgt'>
            {form ? (
              <li onClick={() => handleOptionClick("")}>
                All Rooms<span className='hidden' data-client-id={""}></span>
              </li>
            ) : (
              ""
            )}
            {suggestionsRoom?.length > 0 ? (
              suggestionsRoom?.map((room) => (
                <li key={room._id} onClick={() => handleOptionClick(room)}>
                  {room.name}
                  <span className='hidden' data-room-id={room._id} data-client-id={room.client_id ? room.client_id._id : ""}></span>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        )
      )}
    </div>
  );
};

export default Component;
