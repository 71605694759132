import React, { useState, useEffect, useRef } from "react";
import { url, clientpoints, clientHeader } from "../../../../../../lib/lib.js";
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ setLocationName, searchValueProps, suggestionsProps, showDropdownProps, form = true, setFloorName, setSearchValueFloor, setShowDropdownFloor }) => {
  const { hotelId } = useParams();
  const { searchValueLocation, setSearchValueLocation } = searchValueProps;
  const { suggestionsLocation, setSuggestionsLocation } = suggestionsProps;
  const { showDropdownLocation, setShowDropdownLocation } = showDropdownProps;

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  let [countIndex, setCountIndex] = useState(0);
  const loadOptions = async () => {
    try {
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: searchValueLocation }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsLocation(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: "", page: page, size: perPage, sorting: { createdAt: "1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestionsLocation((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ search: "", filters: { hotel_id: hotelId }, page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsLocation(data);
      setTotalPages(responseData.totalCount);
      setSearchValueLocation("");
    } catch (error) {
      console.error("Error fetching options:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      const { location, _id } = selectedOption;
      setSearchValueLocation(location);
      setLocationName(_id);
      setShowDropdownLocation(location);
      setSuggestionsLocation([]);
    } else if (selectedOption == "") {
      setSearchValueLocation("All Location");
      setShowDropdownLocation("All Location");
      setLocationName("");
      setSuggestionsLocation([]);
    } else {
      setSearchValueLocation("");
      setShowDropdownLocation("");
      setLocationName("");
      setSuggestionsLocation([]);
    }
    setFloorName("");
    setSearchValueFloor("");
    setShowDropdownFloor("");
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestionsLocation([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValueLocation(showDropdownLocation);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdownLocation]);

  return (
    <div className='dropdown' ref={dropdownRef}>
      <i className='ri-arrow-down-s-line ab1' onClick={loadOptionsOnClick}></i>
      <input
        autoComplete='off'
        ref={inputRef}
        placeholder={form ? "Search for Location..." : "Select Location"}
        onChange={(e) => setSearchValueLocation(e.target.value)}
        value={searchValueLocation}
        onKeyUp={() => loadOptions(searchValueLocation)}
        onClick={loadOptionsOnClick}
        id='hotelName'
        className='search-input form-control customSelect w-100'
      />
      {isLoading ? (
        <div className='spinner-border text-primary my_spin' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        suggestionsLocation.length > 0 && (
          <ul id='dropdownMenu' onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className='dropdown-menu mydrop_hgt'>
            {form ? (
              <li onClick={() => handleOptionClick("")}>
                All Location<span className='hidden' data-client-id={""}></span>
              </li>
            ) : (
              ""
            )}
            {suggestionsLocation.length > 0 ? (
              suggestionsLocation.map((location) => (
                <li key={location._id} onClick={() => handleOptionClick(location)}>
                  {location.location}
                  {/* {location.location} {location.email && location.email ? `( ${location.email} ) ` : ''} */}
                  <span className='hidden' data-location-id={location._id} data-client-id={location.client_id ? location.client_id._id : ""}></span>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        )
      )}
    </div>
  );
};

export default Component;
