import { url } from "../lib/lib";

export const validateHotelId = async (hotelId) => {
  try {
    const response = await fetch(url + "/validate-hotelId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ hotel_id: hotelId }),
    });

    if (!response.ok) {
      throw new Error("Hotel Id Validation Failed");
    }

    const data = await response.json();
    return data.Status;
  } catch (error) {
    console.error("Error during hotel id validation:", error.message);
    return false;
  }
};

export const validateHotelIdForTeam = async (hotelName) => {
  try {
    const response = await fetch(url + "/validate-hotelName", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ urlName: hotelName }),
    });

    if (!response.ok) {
      throw new Error("Hotel Id Validation Failed");
    }

    const data = await response.json();
    return data.Status;
  } catch (error) {
    console.error("Error during hotel id validation:", error.message);
    return false;
  }
};

export const getHotelNametoHotelId = async (hotelName) => {
  try {
    const response = await fetch(url + "/hotelName-hotelId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ urlName: hotelName }),
    });

    if (!response.ok) {
      throw new Error("Hotel Id Validation Failed");
    }

    const data = await response.json();
    console.log("67 util ", data);
    if (data.Status) {
      return data;
    } else {
      return data.Status;
    }
  } catch (error) {
    console.error("Error hotel:", error.message);
    return false;
  }
};
