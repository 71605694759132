import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, clientHeader } from "../../../../../../lib/lib.js";
import { toast } from "react-toastify";
import "./component.css";
import SearchableLocationSelect from "../location_select/Component.js";
import SearchableCompanySelect from "../company_select/Component.js";
import SearchableMemberSelect from "../member_select/Component.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [companyName, setCompanyName] = useState("");
  const [searchValueCompany, setSearchValueCompany] = useState("Select Company");
  const [suggestionsCompany, setSuggestionsCompany] = useState([]);
  const [showDropdownCompany, setShowDropdownCompany] = useState("Select Company");

  const [memberName, setMemberName] = useState("");
  const [searchValueMember, setSearchValueMember] = useState("Select Member");
  const [suggestionsMember, setSuggestionsMember] = useState([]);
  const [showDropdownMember, setShowDropdownMember] = useState("Select Member");

  const [locationName, setLocationName] = useState([]);
  const [searchValueLocation, setSearchValueLocation] = useState("Select Location");
  const [suggestionsLocation, setSuggestionsLocation] = useState([]);
  const [showDropdownLocation, setShowDropdownLocation] = useState("Select Location");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = {
      hotel_id: hotelId,
      title: formData.title,
      description: formData.description,
      type: "client",
    };

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.offer_add, {
          method: "POST",
          headers: clientHeader,
          credentials: "include",
          body: JSON.stringify(submissionData),
        });
        setFormData({
          title: "",
          description: "",
        });
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      title: "",
      description: "",
    });
  };

  const addField = () => {
    setAdditionalFields([...additionalFields, ""]);
  };

  const removeField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  const handleChangeDynamicFields = (event, index) => {
    const { value } = event.target;
    const updatedFields = [...additionalFields];
    updatedFields[index] = value;
    setAdditionalFields(updatedFields);
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='Location' className='col-form-label'>
                        Location
                      </label>
                      <SearchableLocationSelect
                        setLocationName={setLocationName}
                        searchValueProps={{ searchValueLocation, setSearchValueLocation }}
                        suggestionsProps={{ suggestionsLocation, setSuggestionsLocation }}
                        showDropdownProps={{ showDropdownLocation, setShowDropdownLocation }}
                        form={true}
                        setCompanyName={setCompanyName}
                        setSearchValueCompany={setSearchValueCompany}
                        setShowDropdownCompany={setShowDropdownCompany}
                        setMemberName={setMemberName}
                        setSearchValueMember={setSearchValueMember}
                        setShowDropdownMember={setShowDropdownMember}
                      />
                    </div>
                    <div className='col-md-12'>
                      <label htmlFor='Company' className='col-form-label'>
                        Company
                      </label>
                      <SearchableCompanySelect
                        setCompanyName={setCompanyName}
                        searchValueProps={{ searchValueCompany, setSearchValueCompany }}
                        suggestionsProps={{ suggestionsCompany, setSuggestionsCompany }}
                        showDropdownProps={{ showDropdownCompany, setShowDropdownCompany }}
                        form={false}
                        setMemberName={setMemberName}
                        setSearchValueMember={setSearchValueMember}
                        setShowDropdownMember={setShowDropdownMember}
                        locationName={locationName}
                      />
                    </div>
                    <div className='col-md-12'>
                      <label htmlFor='Company' className='col-form-label'>
                        Member
                      </label>
                      <SearchableMemberSelect
                        setMemberName={setMemberName}
                        searchValueProps={{ searchValueMember, setSearchValueMember }}
                        suggestionsProps={{ suggestionsMember, setSuggestionsMember }}
                        showDropdownProps={{ showDropdownMember, setShowDropdownMember }}
                        form={false}
                        companyName={companyName}
                      />
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='title' className='col-form-label'>
                        Title
                      </label>
                      <input type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} id='title' name='title' value={formData.title} onChange={handleChange} />
                      {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='description' className='col-form-label'>
                        Description
                      </label>
                      <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                      {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {" "}
                    {isSubmitting ? "Loading..." : "Submit"}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
