import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh }) {
  const navigate = useNavigate();
  const [subscriptionName, setSubscriptionName] = useState("");
  const [price, setPrice] = useState("");
  const [subscriptionRoom, setSubscriptionRoom] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [date, setDate] = useState("");
  const [errors, setErrors] = useState({});
  const [current_id, setCurrent_id] = useState("");
  const [hotel_id, setHotel_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [loading, setLoading] = useState(false);

  let subscriptionId = data;

  const handleSubscriptionNameChange = (event) => {
    setSubscriptionName(event.target.value);
  };
  const handleSubscriptionRoomChange = (event) => {
    setSubscriptionRoom(event.target.value);
  };
  const handleSubscriptionValidityChange = (event) => {
    setSubscriptionValidity(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!subscriptionName.trim()) {
      errors.subscriptionName = "Brand Name is required";
    }
    // if (!price && price !== 0) { errors.price = 'Price is required'; }
    if (!subscriptionRoom.trim()) {
      errors.subscriptionRoom = "Room is required";
    }
    if (!subscriptionValidity.trim()) {
      errors.subscriptionValidity = "Validity is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (isModalOpen && subscriptionId) {
      fetchData(subscriptionId);
    }
  }, [isModalOpen, subscriptionId]);

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(url + adminpoints.subscription_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setHotel_id(response_data.hotel_id._id);
        setClient_id(response_data.client_id._id);
        setCurrent_id(response_data._id);
        setSubscriptionName(response_data.subscription_name || "");
        setPrice(response_data.base_amount || 0);
        setSubscriptionRoom(response_data.subscription_rooms || "");
        // let dateChange = new Date(response_data.subscription_validity).toLocaleDateString();
        setSubscriptionValidity(response_data.subscription_validity || "");
        // setSubscriptionValidity(dateChange || '');
        setDate(response_data.createdAt || "");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
      navigate(`${admin_prefix}login`);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.subscription_edit, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { _id: current_id, hotel_id, client_id },
            data: {
              subscription_name: subscriptionName,
              subscription_rooms: subscriptionRoom,
              subscription_validity: subscriptionValidity,
              base_amount: price,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          toast.success(responseData.Message);
          setSubscriptionName("");
          setPrice("");
          setSubscriptionRoom("");
          setSubscriptionValidity("");
          setIsModalOpen(false);
          onRefresh();
        } else {
          toast.error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Edit Subscription
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='subscriptionName' className='col-form-label'>
                        Subscription Name:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.subscriptionName ? "is-invalid" : ""}`}
                        id='subscriptionName'
                        value={subscriptionName}
                        onChange={handleSubscriptionNameChange}
                      />
                      {errors.subscriptionName && <div className='invalid-feedback'>{errors.subscriptionName}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='subscriptionRoom' className='col-form-label'>
                        Subscription Rooms:
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.subscriptionRoom ? "is-invalid" : ""}`}
                        id='subscriptionRoom'
                        value={subscriptionRoom}
                        onChange={handleSubscriptionRoomChange}
                      />
                      {errors.subscriptionRoom && <div className='invalid-feedback'>{errors.subscriptionRoom}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='subscriptionValidity' className='col-form-label'>
                        Subscription Validity:
                      </label>
                      <input
                        type='date'
                        className={`form-control ${errors.subscriptionValidity ? "is-invalid" : ""}`}
                        id='subscriptionValidity'
                        value={subscriptionValidity}
                        onChange={handleSubscriptionValidityChange}
                      />
                      {errors.subscriptionValidity && <div className='invalid-feedback'>{errors.subscriptionValidity}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='price' className='col-form-label'>
                        Price:
                      </label>
                      <input type='number' className={`form-control`} id='price' value={price} onChange={handlePriceChange} />
                      {/* {errors.price && <div className="invalid-feedback">{errors.price}</div>} */}
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      Close
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
