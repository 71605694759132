import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMutatingDots } from "../../../../components/ui/Loadings";
import useScrollBlock from "../../../../utils/useScrollBlock";

import { url, clientpoints } from "../../../../../../lib/lib";
import RoleModal from "../modals/RoleModal";
import ConfirmModal from "../modals/ConfirmDeleteModal";
import { validateHotelId } from "../../../../../../utils/ValidateHotelId";
import RolesCard from "../card/Cards";

export default function Component() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [roles, setRoles] = useState([]);
  const { hotelId } = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const disableScroll = useScrollBlock();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  const handleSearchExpand = () => {
    setIsExpanded(true);
  };

  const handleSearchClose = () => {
    setSearchTerm("");
    setIsExpanded(false);
    setShowFilterMsg(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters: { hotel_id: hotelId },
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));
      setRoles(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndValidateHotel = async () => {
      const response = await validateHotelId(hotelId);
      if (response) {
        fetchData();
      } else {
        navigate("/hotels");
      }
    };

    fetchDataAndValidateHotel();
  }, [pagination.pageIndex, pagination.pageSize, hotelId]);

  const addFunc = async (item) => {
    try {
      const response = await fetch(url + clientpoints.role_add, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ ...item, hotel_id: hotelId }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      // const newItem = data.data[0];

      if (isExpanded) {
        setSearchTerm("");
        setIsExpanded(false);
        setShowFilterMsg(false);
      }
      handleHardRefresh();
      setIsLoading(false);
      if (data.Status) {
        toast.success(data.Message);
      } else {
        toast.success(data.Message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: 1,
        size: 5,
        filters: { hotel_id: hotelId },
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setRoles(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editFunc = async (newItem) => {
    console.log("updated item", newItem);

    try {
      const response = await fetch(url + clientpoints.role_edit, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({
          filters: { _id: selectedItem._id },
          data: { ...newItem },
        }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      if (isExpanded) {
        handleSearchClose();
      } else {
        setRoles((prev) => prev.map((item) => (item._id === newItem._id ? newItem : item)));
        setIsLoading(false);
      }
      toast.success("Edited successfully!");
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const deleteFunc = async () => {
    console.log("selected item to delete", selectedItem);
    try {
      const response = await fetch(url + clientpoints.role_delete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ filters: { _id: selectedItem._id } }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      if (isExpanded) {
        handleSearchClose();
      } else {
        fetchData();
      }

      // setRoles((prev) => [
      //   ...prev.filter((item) => item._id !== selectedItem._id),
      // ]);
      // setIsLoading(false);
      toast.success("Deleted successfully!");
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    disableScroll(isAddModal || isEditModal || isDeleteModal);
  }, [isAddModal, isEditModal, isDeleteModal]);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        filters: { hotel_id: hotelId },
        sorting: { createdAt: -1 },
      };

      setIsLoading(true);
      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setRoles(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  return (
    <>
      <ToastContainer />
      {isAddModal && <RoleModal saveFunc={addFunc} setIsModal={setIsAddModal} view={"add"} />}
      {isEditModal && <RoleModal editItem={selectedItem} saveFunc={editFunc} view={"edit"} setIsModal={setIsEditModal} />}
      {isDeleteModal && <ConfirmModal heading={`Delete - ${selectedItem.name} ?`} func={deleteFunc} setIsModal={setIsDeleteModal} />}

      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Attendances</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input
                ref={inputRef}
                className={`search-bar ${isExpanded ? "expanded" : ""}`}
                type='text'
                placeholder='Search roles'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={handleSearchSubmit}
              />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>
            <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>
        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {roles?.map((item) => (
                <RolesCard data={item} setSelectedItem={setSelectedItem} setIsEditModal={setIsEditModal} setIsDeleteModal={setIsDeleteModal} />
              ))}
            </div>
            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
