import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="heighlightmy-top">
    <div className="container">
<div className="row">
   
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
    <a href="#contact" className="dop"><div className="lightcard">
    {/* <i class="ri-check-line feature-icn"></i> */}
        {/* <img className="highlight-img" src="/img/hotel.png" alt="Logo" /> */}
        <p className="stay1-txt2">Custom Branded Website</p>
        <p className="stay-txt3">Elevate your online presence with a custom-branded website that perfectly embodies your unique identity.</p>
        <div class="bot-flx1"><button className="freebtn">Get Free Trial <i className="ri-arrow-right-line iconn"></i></button></div>

        </div></a>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
    <a href="#contact"className="dop"><div className="lightcard">
        {/* <img className="highlight-img" src="/img/hotel.png" alt="Logo" /> */}
        <p className="stay1-txt2">White Labeled Apps</p>
        <p className="stay-txt3">Enhance your brand's reach with our white-labeled mobile apps, customized to mirror your brand's ethos seamlessly.</p>
        <div class="bot-flx1"><button className="freebtn">Get Free Trial <i className="ri-arrow-right-line iconn"></i></button></div>

        </div>
        </a>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
    <a href="#contact"className="dop"><div className="lightcard">
        {/* <img className="highlight-img" src="/img/hotel.png" alt="Logo" /> */}
        <p className="stay1-txt2">Dedicated Support </p>
        <p className="stay-txt3">Experience unparalleled service with our dedicated support team, committed to addressing your needs promptly.</p>
        <div class="bot-flx1"><button className="freebtn">Get Free Trial <i className="ri-arrow-right-line iconn"></i></button></div>

        </div></a>
    </div>

</div>
    </div>
    </div>
    
    </>

);
};

export default Component;
