import React from "react";
import "../../../../../nav/components/location/component.css";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

// Card for Areas, Amenities
function Card2({ data, setSelectedItem, setIsViewModal, setIsEditModal, setIsFormulaModal, handleDelete, type = "other", typeTeamRoleObject, whoAmI, tableName }) {
  console.log("dataaaa", data);
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
      <div className='cabins-card'>
        {/* <div class="cabin-sideline"></div> */}
        <div class='cabin-sideline1'></div>
        <div className='row'>
          <div className='col-12'>
            <div className='cabin-sheet'>
              <div className='cabin-number'>
                <i class='ri-keyboard-box-line keybord-icn'></i>
              </div>
              <div className='cabin-text'>
                <p className='cabin-totalseat'>{type === "faq" ? data?.question : type === "area" ? data?.area_name : type === "property" ? data?.title : data?.name}</p>
              </div>
            </div>
            <div className='botflx-cafit'>
              <div className='cabin-editicn'>
                {whoAmI === "team" ? (
                  <>
                    {(typeTeamRoleObject?.room_price_formula.view || typeTeamRoleObject?.room_price_formula.edit || typeTeamRoleObject?.room_price_formula.add) && (
                      <div style={{ marginRight: "10px" }}>
                        <CalculateOutlinedIcon
                          class='ri-pencil-line pencil-icn'
                          onClick={() => {
                            setSelectedItem(data._id);
                            setIsFormulaModal(true);
                          }}
                        />
                      </div>
                    )}
                    {typeTeamRoleObject?.[tableName]?.view && (
                      <i
                        class='ri-eye-line view-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsViewModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.edit && (
                      <i
                        class='ri-pencil-line pencil-icn'
                        onClick={() => {
                          setSelectedItem(data._id);
                          setIsEditModal(true);
                        }}
                      ></i>
                    )}

                    {typeTeamRoleObject?.[tableName]?.delete && (
                      <i
                        class='ri-delete-bin-6-line trash-icn'
                        onClick={() => {
                          handleDelete(data._id, type === "faq" ? data?.question : type === "area" ? data?.area_name : type === "property" ? data?.title : data?.name);
                        }}
                      ></i>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ marginRight: "10px" }}>
                      <CalculateOutlinedIcon
                        class='ri-pencil-line pencil-icn'
                        onClick={() => {
                          setSelectedItem(data._id);
                          setIsFormulaModal(true);
                        }}
                      />
                    </div>
                    <i
                      class='ri-eye-line view-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsViewModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-pencil-line pencil-icn'
                      onClick={() => {
                        setSelectedItem(data._id);
                        setIsEditModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-delete-bin-6-line trash-icn'
                      onClick={() => {
                        handleDelete(data._id, type === "faq" ? data?.question : type === "area" ? data?.area_name : type === "property" ? data?.title : data?.name);
                      }}
                    ></i>
                  </>
                )}
              </div>
              {/* <button className="view-btn-2">Manage Floors</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card2;
