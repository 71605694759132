import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./component.css";
import { url, adminpoints, admin_prefix } from "../../../../lib/lib.js";

const Component = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [isSubmittingLogin, setIsSubmittingLogin] = useState(false);
  const [isSubmittingOTP, setIsSubmittingOTP] = useState(false);

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!username.trim()) {
      setError("Email is required");
      return;
    }
    setIsSubmittingLogin(true);
    try {
      const response = await fetch(url + adminpoints.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "admin",
          authorization: "bdjsbdsjs7bHR@4567",
          key: "ahsnh@AdMinSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();
      if (data.Status) {
        setShowOtpInput(true);
      } else {
        setError(data.Message);
      }
      setLoading(false);
    } catch (error) {
      navigate(`${admin_prefix}login`);
    } finally {
      setIsSubmittingLogin(false);
      setLoading(false);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/\D/g, "");
    if (formattedValue.length > 6) {
      formattedValue = formattedValue.slice(0, 6);
    }
    // formattedValue = formattedValue.padEnd(6, '');
    // setOtp(formattedValue);
    // setOtp(value);
    setOtp(formattedValue);
  };

  const otpHandler = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!otp.trim()) {
      setError("O.T.P is required");
      setLoading(false);
      return;
    } else if (!/^\d{6}$/.test(otp.trim())) {
      setError("O.T.P must be a 6-digit number");
      setLoading(false);
      return;
    }
    setIsSubmittingOTP(true);
    try {
      const response = await fetch(url + adminpoints.otp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "admin",
          authorization: "bdjsbdsjs7bHR@4567",
          key: "ahsnh@AdMinSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ otp }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();

      if (data.Status) {
        navigate(`${admin_prefix}dashboard`);
        setShowOtpInput(true);
      } else {
        setError(data.Message);
      }
      // setLoading(false);
    } catch (error) {
      setError("An error occurred while logging in. Please try again.");
      navigate(`${admin_prefix}login`);
    } finally {
      setIsSubmittingOTP(false);
      setLoading(false);
    }
  };

  return (
    <div className='Inclusive-login-page'>
      <div className='login-big-wrapper'>
        <div className='section-wrapper'>
          <Link to='/'>
            <img className='log-inlogo' src='../../img/staystation.png' alt='Logo' />{" "}
          </Link>

          <div className='top-login-explain'>
            <h2 className='login-txt'>Admin Login</h2>
          </div>

          <form onSubmit={loginHandler}>
            {error && <div className='error_message'>{error}</div>}
            <div className='input-wrapper'>
              <input type='text' required id='username' placeholder='example@gmail.com' onChange={(e) => setUsername(e.target.value)} value={username} tabIndex={1} disabled={showOtpInput} />
              <label htmlFor='username'>Username</label>
            </div>
            <br></br>
            <div className='input-wrapper'>
              <input
                type='password'
                required
                id='password'
                autoComplete='true'
                className='loginPass'
                placeholder='******'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                tabIndex={2}
                disabled={showOtpInput}
              />
              <label htmlFor='password'>Password</label>
            </div>
            {showOtpInput ? (
              ""
            ) : (
              <div>
                <button type='submit' className='subbmitbtn' disabled={isSubmittingLogin}>
                  {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : "Next"}
                </button>
              </div>
            )}
          </form>
          {showOtpInput ? (
            <form onSubmit={otpHandler}>
              {/* {error && <div className="error_message">{error}</div>} */}
              <div className='input-wrapper'>
                <input
                  type='text' // Change input type to text
                  required
                  id='otp'
                  autoComplete='one-time-code' // Use 'one-time-code' for better browser support
                  className='loginPass' // You can style this as needed
                  placeholder='XXXXXX'
                  onChange={handleOtpChange}
                  // onKeyDown={handleKeyDown}
                  value={otp}
                  tabIndex={2}
                />
                <label htmlFor='otp'>OTP</label>
              </div>
              <button type='submit' className='subbmitbtn' isabled={isSubmittingOTP}>
                {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : "Submit"}
              </button>
              <p
                className='try_again'
                onClick={() => {
                  setShowOtpInput(false);
                  setError("");
                  setOtp("");
                }}
              >
                Facing any issues? <span className='hover_class_change'>Please try again.</span>
              </p>
            </form>
          ) : (
            ""
          )}
        </div>
        <div className='login-banner-section '>
          <img src='../../img/login2.png' alt='banner' height='100%' className='img-fluid' />
        </div>
      </div>
    </div>
  );
};

export default Component;
