import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { classNames } from "primereact/utils";
import toast, { Toaster } from "react-hot-toast";
import "./component.css";
import { endpoints, url } from "../../../lib/lib.js";

const Component = () => {
  const notify = () => toast.success("Message Sent Successfully.");
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" }); // Clear error when user starts typing
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Invalid phone number.";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      notify(); // Show toast message

      fetch(url + endpoints.contact_us, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Status) {
          } else {
            throw new Error(data.Message);
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
  };

  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-section' id='contact'>
        <center>
          <p className='staystation-feature'>Get Free Trial</p>

          <hr className='pricing-line1'></hr>
        </center>
        <div className='container-fluid'>
          <div className='img-rel'>
            <img src='img/ct1.png' className='img-fluid ct1-img' />
          </div>
          <div className='img-rel'>
            <img src='img/ct2.png' className='img-fluid ct2-img' />
          </div>
          <div className='img-rel'>
            <img src='img/ct2.png' className='img-fluid ct3-img' />
          </div>
          <div className='row'>
            <div className='col-sm-3'></div>
            <div className='col-sm-6'>
              <center>
                <p className='demo-txt'>
                  Contact us now to request a free trial and we'll be in touch to get you started. With no obligation to purchase, you can experience all the benefits of our product.
                </p>
              </center>
              <div className='form-top'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <input type='text' className={`form-control feald-hgt ${formErrors.name && "error-border"}`} placeholder='Name' name='name' value={formData.name} onChange={handleInputChange} />
                    {formErrors.name && (
                      <span className='error' style={{ color: "red" }}>
                        {formErrors.name}
                      </span>
                    )}
                  </div>

                  <div className='form-group'>
                    <input type='tel' className={`form-control feald-hgt ${formErrors.phone && "error-border"}`} placeholder='Phone' name='phone' value={formData.phone} onChange={handleInputChange} />
                    {formErrors.phone && (
                      <span className='error' style={{ color: "red" }}>
                        {formErrors.phone}
                      </span>
                    )}
                  </div>

                  <div className='form-group'>
                    <input
                      type='email'
                      className={`form-control feald-hgt ${formErrors.email && "error-border"}`}
                      placeholder='Email'
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {formErrors.email && (
                      <span className='error' style={{ color: "red" }}>
                        {formErrors.email}
                      </span>
                    )}
                  </div>

                  <div className='form-group'>
                    <textarea
                      className={`form-control resize1 ${formErrors.message && "error-border"}`}
                      rows='4'
                      placeholder='Message'
                      name='message'
                      value={formData.message}
                      onChange={handleInputChange}
                    ></textarea>
                    {formErrors.message && (
                      <span className='error' style={{ color: "red" }}>
                        {formErrors.message}
                      </span>
                    )}
                  </div>

                  <button type='submit' className='call-bot'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className='col-sm-3'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
