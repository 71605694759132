import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import "./Component.css";

export const MultiCheck = ({ label, options, selected, name, onCheck, optionType = "string", optionLabel = "name", optionValue = "_id", disabled }) => {
  const handleCheck = (item) => {
    if (optionType === "string") {
      if (selected.includes(item))
        onCheck(
          name,
          selected.filter((slt) => slt !== item)
        );
      else onCheck(name, [...selected, item]);
    } else {
      if (selected.includes(item[optionValue]))
        onCheck(
          name,
          selected.filter((slc) => slc !== item[optionValue])
        );
      else onCheck(name, [...selected, item[optionValue]]);
    }
  };
  return (
    <div className='multi-check-div'>
      {label}
      <div className='multi-check-box'>
        {options.map((item, i) => (
          <label
            key={i}
            className={`label ${optionType === "string" ? (selected.includes(item) ? "selected" : "normal") : selected.includes(item[optionValue]) ? "selected" : "normal"}`} // style={{ border: "2px solid" }}
          >
            <input disabled={disabled} type='checkbox' onChange={() => handleCheck(item)} checked={selected.includes(optionType === "string" ? item : item[optionValue])} />
            <span>{selected.includes(optionType === "string" ? item : item[optionValue]) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</span>
            {optionType === "string" ? item : item[optionLabel]}
          </label>
        ))}
      </div>
    </div>
  );
};
