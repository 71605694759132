import React, { useEffect, useState, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { url, getDecryptedDataFromLocalStorage } from "../../lib/lib";
import { AuthUserContext } from "../../lib/AuthUserContext";
import { LoadingMutatingDots } from "../../panels/hotels/components/ui/Loadings";

function TeamPrivateRoutes() {
  const [cookies] = useCookies(["access_team"]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let getLocalStorageData;

  let requestBody;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      console.log("aa", getLocalStorageData);
      setWhoAmI(getLocalStorageData);
      if (getLocalStorageData == "team") {
        requestBody = { type: getLocalStorageData };
      } else {
        requestBody = {};
      }
    } else {
      if (whoAmI == "team") {
        requestBody = { type: whoAmI };
      } else {
        requestBody = {};
      }
    }
  }, []);

  useEffect(() => {
    async function authenticate() {
      try {
        let response = await fetch(url + "/frontend-auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
          credentials: "include",
        });

        const data = await response.json();

        if (data.Status) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        console.log("falseeeeeeee");
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }
    authenticate();
  }, [cookies]);

  if (isLoading) {
    return <LoadingMutatingDots />;
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default TeamPrivateRoutes;
