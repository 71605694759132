import { useState, useEffect } from "react";
import Modal from "./Modal";
import { validate } from "../../../../../../utils/ValidateForms";
import { toast } from "react-toastify";

export default function RoleModal({ saveFunc, setIsModal, view = "add", editItem = {} }) {
  const [modal, setModal] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    amenitie: { add: false, edit: false, delete: false, view: false },
    area: { add: false, edit: false, delete: false, view: false },
    cabin: { add: false, edit: false, delete: false, view: false },
    location: { add: false, edit: false, delete: false, view: false },
    meeting: { add: false, edit: false, delete: false, view: false },
    seat: { add: false, edit: false, delete: false, view: false },
    hotel: { add: false, edit: false, delete: false, view: false },
    team: { add: false, edit: false, delete: false, view: false },
    ticket: { add: false, edit: false, delete: false, view: false },
    user: { add: false, edit: false, delete: false, view: false },
  });

  const handleChangeInput = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCheckboxChange = (role, action) => {
    setFormData({
      ...formData,
      [role]: {
        ...formData[role],
        [action]: !formData[role][action],
      },
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const verify = validate(formData, requiredFields);
    if (!verify.success) return toast.error(verify.message);
    const newData = {
      ...editItem,
      ...formData,
      // createdAt: editItem?.createdAt || new Date(),
    };
    saveFunc(newData);
    setModal(false);
  };

  const putValues = () => {
    setFormData(editItem);
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (view === "edit") putValues();
  }, []);

  return (
    <Modal setIsModal={setIsModal} modal={modal}>
      <h2>{view === "edit" ? "Edit" : "Add"} Role </h2>
      <form onSubmit={handleSave} className='flex flex-col gap-6 p-4 sm:p-8 text-[14px] text-gray-600 w-full'>
        <label className='flex flex-col gap-1'>
          Role Name
          <input type='text' name='name' value={formData.name} onChange={handleChangeInput} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
        </label>

        {formData && typeof formData === "object" && (
          <div className='flex flex-col items-center'>
            <table className='border-collapse w-full'>
              <thead>
                <tr className='bg-gray-200'>
                  <th className='border border-gray-400'>Name</th>
                  <th className='border border-gray-400'>Add</th>
                  <th className='border border-gray-400'>Edit</th>
                  <th className='border border-gray-400'>View</th>
                  <th className='border border-gray-400'>Delete</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(formData)
                  .filter(
                    ([roleName, roleData]) =>
                      roleName === "amenitie" ||
                      roleName === "area" ||
                      roleName === "cabin" ||
                      roleName === "location" ||
                      roleName === "meeting" ||
                      roleName === "seat" ||
                      roleName === "hotel" ||
                      roleName === "team" ||
                      roleName === "ticket" ||
                      roleName === "user"
                  )
                  .map(([roleName, roleData]) => (
                    <tr key={roleName}>
                      <td className='border border-gray-400'>{roleName}</td>
                      <td className='border border-gray-400'>
                        <input type='checkbox' checked={roleData.add} onChange={() => handleCheckboxChange(roleName, "add")} />
                      </td>
                      <td className='border border-gray-400'>
                        <input type='checkbox' checked={roleData.edit} onChange={() => handleCheckboxChange(roleName, "edit")} />
                      </td>
                      <td className='border border-gray-400'>
                        <input type='checkbox' checked={roleData.view} onChange={() => handleCheckboxChange(roleName, "view")} />
                      </td>
                      <td className='border border-gray-400'>
                        <input type='checkbox' checked={roleData.delete} onChange={() => handleCheckboxChange(roleName, "delete")} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        <button type='submit' className='p-2 mt-2 bg-tl_primary text border border-tl_primary text-white font-bold hover:scale-105 rounded-md transition-all duration-300 flex-1'>
          Save
        </button>
      </form>
    </Modal>
  );
}

const requiredFields = [];
