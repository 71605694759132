import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { toast } from "react-toastify";
import "./component.css";
import { Link, useNavigate } from "react-router-dom";
import SearchableSelectComponentHotel from "../hotel_client_select/Component.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const [hotelName, setHotelName] = useState("");
  const [clientName, setClientName] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setHotelList(data.Data);
    } catch (error) {
      toast.error(error);
      // console.error('Error fetching client data:', error.message);
    }
  };

  const handleClientNameChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const clientId = selectedOption.dataset.clientId;
    setHotelName(event.target.value);
    setClientName(clientId);
  };

  const [formData, setFormData] = useState({
    title: "",
    comment: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!hotelName.trim()) {
      errors.hotelName = "Client is required";
      isValid = false;
    }
    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.comment.trim()) {
      errors.comment = "Comment is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.client_ticket_add, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            client_id: clientName,
            hotel_id: hotelName,
            title: formData.title,
          }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          let client_ticket_id = responseData._id;
          const response_history = await fetch(url + adminpoints.client_ticket_history_add, {
            method: "POST",
            headers: adminHeader,
            credentials: "include",
            body: JSON.stringify({
              client_id: clientName,
              hotel_id: hotelName,
              client_ticket_id: client_ticket_id,
              comment: formData.comment,
              type: "admin",
            }),
          });
          setHotelName("");
          setFormData({
            title: "",
            comment: "",
          });
          setShowDropdown("");

          const responseDataHistory = await response_history.json();
          if (responseDataHistory.Status) {
            toast.success(responseDataHistory.Message);
          } else {
            toast.error(responseDataHistory.Message);
          }
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHotelName("");
    setFormData({
      title: "",
      comment: "",
    });
    setShowDropdown("");
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='mb-3'>
                    <label htmlFor='hotelName' className='col-form-label'>
                      Hotel Name:
                    </label>
                    <SearchableSelectComponentHotel
                      setClientName={setClientName}
                      setHotelName={setHotelName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />

                    {/* <select id="hotelName" className={`form-select ${errors.hotelName ? 'is-invalid' : ''}`} value={hotelName} onChange={handleClientNameChange}>
                      <option value="">Select Hotel</option>
                      {hotelList.map((hotel) => (
                        <option key={hotel._id} value={hotel._id} data-client-id={hotel.client_id ? hotel.client_id._id : ''}>
                          {hotel.hotel_name ? `${hotel.hotel_name} ` : ''}
                          {hotel.client_id && hotel.client_id.name ? `( ${hotel.client_id.name} ) ` : ''}
                          {hotel.client_id && hotel.client_id.email ? `( ${hotel.client_id.email} ) ` : ''}
                          {hotel.client_id && hotel.client_id.phone ? `( ${hotel.client_id.phone} ) ` : ''}
                        </option>
                      ))}
                    </select> */}
                    {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label htmlFor='title' className='col-form-label'>
                        Title
                      </label>
                      <input type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} id='title' name='title' value={formData.title} onChange={handleChange} />
                      {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label htmlFor='comment' className='col-form-label'>
                        Comment:
                      </label>
                      <textarea type='text' className={`form-control ${errors.comment ? "is-invalid" : ""}`} id='comment' value={formData.comment} name='comment' onChange={handleChange} />
                      {errors.comment && <div className='invalid-feedback'>{errors.comment}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
