import { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import useScrollBlock from "../../../utils/useScrollBlock";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "../../../../nav/components/location/component.css";
import { url, clientpoints, getDecryptedDataFromLocalStorage, whoAmIHeaders, client_prefix } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";

function Component() {
  const navigate = useNavigate();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [stateDisabled, setStateDisabled] = useState(false);

  useEffect(() => {
    if (typeTeamRoleObject.hotel) {
      if (typeTeamRoleObject?.hotel?.edit) {
        setStateDisabled(true);
      } else {
        setStateDisabled(false);
      }
    } else {
      setStateDisabled(true);
    }
  }, [typeTeamRoleObject]);

  // const initialAccountStatus = typeTeamRoleObject?.hotel?.edit ? true : false;
  // const [stateDisabled, setStateDisabled] = useState(initialAccountStatus);

  const { hotelId } = useParams();
  const [hotelData, setHotelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHotelData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.hotel_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          // urlName: hotelId,
        }),
      });

      // Check if request was successful
      if (response.ok) {
        // Parse response JSON
        const data = await response.json();
        // Store fetched data in state
        setHotelData(data.Data[0]);
        setIsLoading(false); // Update loading state
      } else {
        // Handle error if request fails
        throw new Error("Failed to fetch hotel data");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch hotel data");
      setIsLoading(false); // Update loading state
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHotelData();
  }, []);

  const [formData, setFormData] = useState({
    hotel_name: "",
    billing_detail: {
      legal_company: "",
      billing_address: "",
      gstn: "",
      pan: "",
      cin: "",
      tan: "",
    },
    phone: "",
    email: "",
    description: "",
  });

  useEffect(() => {
    if (hotelData) {
      setFormData((prevState) => ({
        ...prevState,
        hotel_name: hotelData.hotel_name || "",
        billing_detail: {
          legal_company: hotelData.billing_detail.legal_company || "",
          billing_address: hotelData.billing_detail.billing_address || "",
          gstn: hotelData.billing_detail.gstn || "",
          pan: hotelData.billing_detail.pan || "",
          cin: hotelData.billing_detail.cin || "",
          tan: hotelData.billing_detail.tan || "",
        },
        phone: hotelData.phone || "",
        email: hotelData.email || "",
        description: hotelData.description || "",
      }));
    }
  }, [hotelData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the field is in billing_detail or not
    if (name.startsWith("billing_detail.")) {
      setFormData((prevState) => ({
        ...prevState,
        billing_detail: {
          ...prevState.billing_detail,
          [name.split(".")[1]]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(url + clientpoints.hotel_edit, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          // urlName: hotelId,
          data: formData,
        }),
      });

      console.log("to updateee", formData);

      if (response.ok) {
        // Data updated successfully
        toast.success("Hotel data updated successfully");
      } else {
        throw new Error("Failed to update hotel data");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update hotel data");
    }
  };

  const handleRefresh = () => {
    fetchHotelData();
  };

  return (
    <>
      <ToastContainer />
      <div className='inner-padding staff-table-parent'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Hotel Details</p>
          </div>

          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleRefresh}>
              {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
            </button>
          </div>
        </div>

        <div className='row'>
          {isLoading ? (
            <p>Loading...</p>
          ) : hotelData ? (
            <>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Hotel Name*</label>
                    <input type='text' className='form-control family' name='hotel_name' value={formData.hotel_name} onChange={handleChange} placeholder='Enter  Hotel Name' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Phone*</label>
                    <input type='text' className='form-control family' name='phone' value={formData.phone} onChange={handleChange} placeholder='Enter  Phone number' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Email*</label>
                    <input type='email' className='form-control family' name='email' value={formData.email} onChange={handleChange} placeholder='Enter Email' required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>
              <div className='row w-100 mb-4'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='family'>Description*</label>
                    <textarea className='form-control family' name='description' value={formData.description} onChange={handleChange} placeholder='Enter  Description' required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>

              <div className='featured-flx1'>
                <p className='main_heading1'>Billing Details</p>
              </div>

              <div className='row w-100 mt-4'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Legal Company</label>
                    <input type='text' className='form-control family' name='billing_detail.legal_company' value={formData.billing_detail.legal_company} onChange={handleChange} placeholder='Enter legal company' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Billing Address</label>
                    <input type='text' className='form-control family' name='billing_detail.billing_address' value={formData.billing_detail.billing_address} onChange={handleChange} placeholder='Enter billing address' required disabled={!stateDisabled} />{" "}
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Gstn</label>
                    <input type='text' className='form-control family' name='billing_detail.gstn' value={formData.billing_detail.gstn} onChange={handleChange} placeholder='Enter gstn' required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>
              <div className='row w-100'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Pan</label>
                    <input type='text' className='form-control family' name='billing_detail.pan' value={formData.billing_detail.pan} onChange={handleChange} placeholder='Enter pan' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Cin</label>
                    <input type='text' className='form-control family' name='billing_detail.cin' value={formData.billing_detail.cin} onChange={handleChange} placeholder='Enter Cin' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Tan</label>
                    <input type='text' className='form-control family' name='billing_detail.tan' value={formData.billing_detail.tan} onChange={handleChange} placeholder='Enter tan' required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>

              {whoAmI === "team" ? (
                <>
                  {typeTeamRoleObject?.hotel?.edit && (
                    <button type='button' className='btn btn-primary mt-4 mb-4' onClick={handleSubmit}>
                      Update
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-primary mt-4 mb-4' onClick={handleSubmit}>
                    Update
                  </button>
                </>
              )}
            </>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Component;
