import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./screens/Dashboard.js";
import Client from "./screens/Client.js";
import Hotel from "./screens/Hotel.js";
import Support from "./screens/Support.js";
import Subscription from "./screens/Subscription.js";
import PrivateRoutes from "./utils/PrivateRoute.js";
import PublicRoutes from "./utils/PublicRoute.js";
import Login from "./components/login/Component.js";
import "react-toastify/dist/ReactToastify.css";

const AdminRoute = () => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<PublicRoutes />}>
          <Route path='login' element={<Login />} />
        </Route>

        <Route path='/' element={<PrivateRoutes />}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='hotel' element={<Hotel />} />
          <Route path='client' element={<Client />} />
          <Route path='support' element={<Support />} />
          <Route path='subscription' element={<Subscription />} />
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoute;
