import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, clientHeader } from "../../../../../../lib/lib.js";
import SearchableLocationSelect from "../location_select/Component.js";
import SearchableFloorSelect from "../floor_select/Component.js";

import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [locationName, setLocationName] = useState("");
  const [searchValueLocation, setSearchValueLocation] = useState("Select Location");
  const [suggestionsLocation, setSuggestionsLocation] = useState([]);
  const [showDropdownLocation, setShowDropdownLocation] = useState("Select Location");

  const [floorName, setFloorName] = useState("");
  const [searchValueFloor, setSearchValueFloor] = useState("Select Floor");
  const [suggestionsFloor, setSuggestionsFloor] = useState([]);
  const [showDropdownFloor, setShowDropdownFloor] = useState("Select Floor");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.hotel_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ filters: { hotel_id: hotelId } }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setHotelList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!locationName.trim()) {
      alert("Location is required");
      errors.locationName = "Location is required";
      isValid = false;
    }
    if (!floorName.trim()) {
      alert("Floor is required");
      errors.floorName = "Floor is required";
      isValid = false;
    }
    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = {
      hotel_id: hotelId,
      title: formData.title,
      description: formData.description,
      question: additionalFields,
      location_id: locationName,
      floor_id: floorName,
      type: "client",
    };

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.event_add, {
          method: "POST",
          headers: clientHeader,
          credentials: "include",
          body: JSON.stringify(submissionData),
        });
        setLocationName("");
        setFloorName("");
        setFormData({
          title: "",
          description: "",
        });
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLocationName("");
    setFloorName("");
    setFormData({
      title: "",
      description: "",
    });
  };

  const addField = () => {
    setAdditionalFields([...additionalFields, ""]);
  };

  const removeField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  const handleChangeDynamicFields = (event, index) => {
    const { value } = event.target;
    const updatedFields = [...additionalFields];
    updatedFields[index] = value;
    setAdditionalFields(updatedFields);
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='Location' className='col-form-label'>
                        Location
                      </label>
                      <SearchableLocationSelect
                        setLocationName={setLocationName}
                        searchValueProps={{ searchValueLocation, setSearchValueLocation }}
                        suggestionsProps={{ suggestionsLocation, setSuggestionsLocation }}
                        showDropdownProps={{ showDropdownLocation, setShowDropdownLocation }}
                        form={false}
                        setFloorName={setFloorName}
                        setSearchValueFloor={setSearchValueFloor}
                        setShowDropdownFloor={setShowDropdownFloor}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='Location' className='col-form-label'>
                        Floor
                      </label>
                      <SearchableFloorSelect
                        setFloorName={setFloorName}
                        searchValueProps={{ searchValueFloor, setSearchValueFloor }}
                        suggestionsProps={{ suggestionsFloor, setSuggestionsFloor }}
                        showDropdownProps={{ showDropdownFloor, setShowDropdownFloor }}
                        form={false}
                        locationName={locationName}
                      />
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='title' className='col-form-label'>
                        Title
                      </label>
                      <input type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} id='title' name='title' value={formData.title} onChange={handleChange} />
                      {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='description' className='col-form-label'>
                        Description
                      </label>
                      <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                      {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                    </div>

                    {additionalFields.map((value, index) => (
                      <div key={index} className='col-md-12'>
                        <label htmlFor={`additionalField${index}`} className='col-form-label'>
                          Question {index + 1}
                        </label>
                        <div className='input-group'>
                          <input
                            type='text'
                            className='form-control'
                            id={`additionalField${index}`}
                            name={`additionalField${index}`}
                            value={value}
                            onChange={(event) => handleChangeDynamicFields(event, index)}
                          />
                          <button type='button' className='btn btn-danger' style={{ position: "static", height: "fit-content", padding: "8px" }} onClick={() => removeField(index)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}

                    {/* Button to add more input fields */}
                    <div className='col-md-12'>
                      <button type='button' className='btn btn-success' onClick={addField}>
                        Add Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {" "}
                    {isSubmitting ? "Loading..." : "Submit"}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
