import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="container">
<div className="row top-header">
    <div className="col-sm-12">
        <p className="stay-txt">
            Hotel Management <span className="backtxt">Software</span>
        </p>
        <p className="stay-desc">
        Experience seamless hotel management with StayStation, all-in-one solution for streamlining every aspect of your hotel's operations. Effortlessly oversee everything from reservations and guest services to staff coordination, all from one intuitive dashboard.
        </p>
        
    </div>
</div>
    </div>
    
    </>

);
};

export default Component;
