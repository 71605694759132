import TableComponent from "../components/hotel_ui/table/Component.js";

const Hotel = () => {
  return (
    <>
      <TableComponent />
    </>
  );
};

export default Hotel;
