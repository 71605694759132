import React, { useRef, useState } from "react";
import "./cards.css";

function Card1({ data, setSelectedItem, setIsModalOpenEdit, setIsDeleteModal }) {

    return (
        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
            <div className='post-card'>
                <div class="post-sideline1"></div>
                <img src="/img/events.jpg" className='img-fluid post-image' />
                <div className='row over-dropdown'>
                    <div className='col-12'>
                        <div class="droptop ">
                            <div type="button" class="show" >
                                <i className="ri-more-2-line more-detail"></i>
                                <div className='cabin-editicn'>
                                    <i class='ri-pencil-line pencil-icn' onClick={() => { setSelectedItem(data); setIsModalOpenEdit(true); }} ></i>
                                    <i class='ri-delete-bin-6-line trash-icn' onClick={() => { setSelectedItem(data); setIsDeleteModal(true); }} ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='post-inner-txt'>
                    <p class="posts-title">{(data.title) && (data.title)}</p>
                    <p class="posts-txt">{(data.description) && (data.description)}</p>
                </div>
            </div>
        </div>
    );
}

export default Card1;
